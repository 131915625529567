import { useMemo, useEffect, useCallback } from "react";
import { checkBullFinish } from "../../helper/scoring/utils";
import socket from "../../socket";
import {
  handleBullScoreSocketEvent,
  handleBullChallengerFinishSocketEvent,
} from "../../socket/scoringSocketEvent";

const useBullFinish = (
  isChallenger,
  isFinish,
  scoreHistory,
  opponentHistory,
  user,
  opponentUser,
  token
) => {
  // const isFinish = useMemo(
  //   () => checkBullFinish(scoreHistory, opponentHistory),
  //   [scoreHistory, opponentHistory]
  // );

  const userScore = useMemo(
    () => scoreHistory.reduce((acc, cur) => acc + cur, 0),
    [scoreHistory]
  );
  const opponentScore = useMemo(
    () => opponentHistory.reduce((acc, cur) => acc + cur, 0),
    [opponentHistory]
  );

  const handleBullFinish = useCallback(() => {
    handleBullScoreSocketEvent(userScore, token, user, opponentUser);
  }, [user, userScore, token, opponentUser]);

  useEffect(() => {
    if (isFinish && isChallenger) {
      handleBullChallengerFinishSocketEvent(
        userScore,
        token,
        user,
        opponentUser
      );
    }
  }, [isFinish, isChallenger, token, user, userScore, opponentUser]);

  useEffect(() => {
    socket.on("bull-challenger-finish-response", handleBullFinish);
    return () => {
      socket.off("bull-challenger-finish-response", handleBullFinish);
    };
  }, [handleBullFinish]);

  return {
    userScore,
    opponentScore,
  };
};

export default useBullFinish;
