import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { getRandomColor } from "../../helper/helper";
import ToolTip from "../ToolTip/ToolTip";

const MatchResultCard = ({ match }) => {
  const navigate = useNavigate();
  const { results, player1, player2 } = match;

  const [player1Wins, setPlayer1Wins] = useState(0);
  const [player2Wins, setPlayer2Wins] = useState(0);

  useEffect(() => {
    let p1Wins = 0;
    let p2Wins = 0;

    results.forEach((result) => {
      if (result.winner === player1) {
        p1Wins += 1;
      } else if (result.winner === player2) {
        p2Wins += 1;
      }
    });

    setPlayer1Wins(p1Wins);
    setPlayer2Wins(p2Wins);
  }, [results, player1, player2]);

  const bgColor1 = useMemo(() => {
    return getRandomColor(player1) || "";
  }, [player1]);

  const bgColor2 = useMemo(() => {
    return getRandomColor(player2) || "";
  }, [player2]);

  const handleWatchReply = () => {
    navigate(`/arena/reply`, { state: { match, isReply: true } });
  };

  return (
    <div
      className="flex flex-col gap-4 w-96 h-60 p-4 bg-white dark:bg-gray-900 rounded-md shadow-md hover:border hover:border-yellow-400 hover:cursor-pointer"
      onClick={handleWatchReply}
    >
      <div className="flex items-center justify-center gap-16 p-2 rounded-md dark:bg-gray-800">
        <ToolTip text={player1}>
          <p
            style={{ backgroundColor: bgColor1 }}
            className={`flex items-center justify-center text-2xl text-white font-bold uppercase rounded-full shadow-md w-16 h-16`}
          >
            {player1.charAt(0)}
          </p>
        </ToolTip>
        <p className="text-xl italic">vs</p>
        <ToolTip text={player2}>
          <p
            style={{ backgroundColor: bgColor2 }}
            className={`flex items-center justify-center text-2xl text-white font-bold uppercase rounded-full shadow-md w-16 h-16`}
          >
            {player2.charAt(0)}
          </p>
        </ToolTip>
      </div>
      <div className="font-normal text-xl">
        {new Date(match?.date).toLocaleString()}
      </div>
      <div className="flex items-center justify-center gap-8 text-5xl italic">
        <p>{player1Wins}</p> : <p>{player2Wins}</p>
      </div>
    </div>
  );
};

export default MatchResultCard;
