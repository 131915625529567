import { useTranslation } from "react-i18next";
import AvatarCard from "../../components/Shop/AvatarCard";
import useAvatarShop from "../../hooks/useAvatarShop";

const AvatarShop = () => {
  const { t } = useTranslation();

  const { user, displayAvatars, handleLvlUp } = useAvatarShop();

  return (
    <div className="text-black dark:text-white">
      <h1 className="font-semibold text-6xl uppercase mb-12">
        {t("avatarShop")}
      </h1>
      {/* display cards for buying virtual avatar */}
      <div className="flex justify-center flex-wrap gap-4">
        {displayAvatars?.map((rankAvatars, index) => (
          <div
            key={index}
            className="flex flex-col gap-4 justify-center items-center border-b border-b-gray-600 pb-4"
          >
            <h1 className="font-semibold text-2xl uppercase mb-4">
              {t(`rank${index + 1}`)}
            </h1>
            <div className="flex gap-4 flex-wrap justify-center">
              {rankAvatars.map((avatar) => (
                <AvatarCard
                  key={avatar.title}
                  user={user}
                  avatar={avatar}
                  handleLvlUp={handleLvlUp}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvatarShop;
