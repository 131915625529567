import { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import http from "../../helper/http-client";
import { handleAchievement } from "../../helper/result";

export const useScoringFightResult = (token) => {
  const navigate = useNavigate();
  const { allUsers } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [fightResult, setFightResult] = useState({});
  const [earnedAchievement, setEarnedAchievement] = useState({
    user1: [],
    user2: [],
  });

  const winUser = useMemo(
    () =>
      fightResult?.p1?.legs_won >= fightResult?.p2?.legs_won
        ? {
            name: fightResult?.p1?.name,
            scoreHistory: fightResult?.p1?.scoreHistory,
            won: fightResult?.p1?.legs_won,
            info: allUsers.find(
              (val) =>
                val?.username?.toLowerCase() ===
                fightResult?.p1?.name?.toLowerCase()
            ),
            achievements: earnedAchievement?.user1,
          }
        : {
            name: fightResult?.p2?.name,
            scoreHistory: fightResult?.p2?.scoreHistory,
            won: fightResult?.p2?.legs_won,
            info: allUsers.find(
              (val) =>
                val?.username?.toLowerCase() ===
                fightResult?.p2?.name?.toLowerCase()
            ),
            achievements: earnedAchievement?.user2,
          },
    [fightResult, allUsers, earnedAchievement]
  );

  const loseUser = useMemo(
    () =>
      fightResult?.p1?.legs_won >= fightResult?.p2?.legs_won
        ? {
            name: fightResult?.p2?.name,
            scoreHistory: fightResult?.p2?.scoreHistory,
            won: fightResult?.p2?.legs_won,
            info: allUsers.find(
              (val) =>
                val?.username?.toLowerCase() ===
                fightResult?.p2?.name?.toLowerCase()
            ),
            achievements: earnedAchievement?.user2,
          }
        : {
            name: fightResult?.p1?.name,
            scoreHistory: fightResult?.p1?.scoreHistory,
            won: fightResult?.p1?.legs_won,
            info: allUsers.find(
              (val) =>
                val?.username?.toLowerCase() ===
                fightResult?.p1?.name?.toLowerCase()
            ),
            achievements: earnedAchievement?.user1,
          },
    [fightResult, allUsers, earnedAchievement]
  );

  const fetchMatchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await http.get(`/score/get/match-status/${token}`);
      const p1EarnedAchievements = handleAchievement(
        data.p1.updatedResult,
        data.p1.initialResult,
        data.p1.scoreHistory
      );
      const p2EarnedAchievements = handleAchievement(
        data.p2.updatedResult,
        data.p2.initialResult,
        data.p2.scoreHistory
      );

      setEarnedAchievement({
        user1: p1EarnedAchievements,
        user2: p2EarnedAchievements,
      });

      setFightResult(data);
    } catch (err) {
      console.log("fetchMatchData error-->>", err);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchMatchData();
  }, [fetchMatchData]);

  const handleGoBack = () => {
    navigate("/");
  };

  return {
    isLoading,
    winUser,
    loseUser,
    fightResult,
    handleGoBack,
  };
};
