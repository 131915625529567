import React from "react";
import { useSelector } from "react-redux";

const ShowXP = () => {
  const { user, isLoading } = useSelector((state) => state.auth);
  const { xp, customBalance, defaultBalance, stamina } = user;

  let customPrice = customBalance < 0 ? 0 : customBalance;
  let defaultPrice = defaultBalance < 0 ? 0 : defaultBalance;

  const data = [
    {
      title: "XP",
      value: Math.floor(xp),
      color: "bg-green-500",
    },
    {
      title: "Balance",
      value: customPrice + defaultPrice,
      color: "bg-blue-500",
    },
    {
      title: "Stamina",
      value: stamina,
      color: "bg-red-500",
    },
  ];

  console.log(xp, customBalance, defaultBalance, stamina);
  return (
    <div className="absolute top-[72px] right-0 flex">
      {isLoading
        ? null
        : data?.map((item, index) => (
            <div key={index} className="flex items-center text-xs uppercase">
              <p
                className={`flex-1 ${item.color} py-1 px-3 text-white font-bold`}
              >
                {item.title}
              </p>
              <p className="flex-1 bg-gray-100 py-1 px-3">{item.value}</p>
            </div>
          ))}
    </div>
  );
};

export default React.memo(ShowXP);
