import { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router";
import images from "../helper/images";
import useScoringSystem from "../hooks/Scoring/useScoringSystem";
import ConfirmModal from "../components/ComfirmModal";
import ScoringModal from "../components/Scoring/ScoringModal";
import AchievementModal from "../components/Scoring/AchievementModal";
import useCheckDouble from "../hooks/Scoring/useCheckDouble";
import BulloutCheck from "../components/Scoring/BulloutCheck";
import ScoringPageHeader from "../components/Scoring/ScoringPageHeader";
import ScoringPlay from "../components/Scoring/ScoringPlay";
import { Keyboard, X } from "lucide-react";

const ScoringPage = () => {
  const { opponent, challenger, token } = useParams();
  const navigate = useNavigate();

  const {
    user,
    opponentUser,
    userCurrentScore,
    opponentCurrentScore,
    userScoreHistory,
    opponentScoreHistory,
    userWin,
    opponentWin,
    legNo,
    userTurn,
    modalType,
    isModalOpen,
    isFinished,
    isFinishModal,
    isBackModal,
    isBullModalOpen,
    isKeyboardVisible,
    isEnableKeyEvent,
    isUndo,
    isLoading,
    fetchUserScore,
    handleSubmit,
    handleGameFinish,
    handleFinishClick,
    handleBack,
    setIsBackModal,
    setIsFinishModal,
    setIsModalOpen,
    handleSubmitModal,
    handleUndo,
    onChangeKeyboardVisible,
    onClickUndo,
  } = useScoringSystem(opponent, challenger, token);
  const { checkDouble, possibleCheckArr, possibleDoubleArr } =
    useCheckDouble(userCurrentScore);

  const onSubmit = useCallback(
    (score) => {
      checkDouble(score);
      handleSubmit(score);
    },
    [checkDouble, handleSubmit]
  );

  const onUndo = useCallback(
    (score) => {
      const lastIdx = userScoreHistory[legNo - 1].length - 1;
      const lastScore = userScoreHistory[legNo - 1][lastIdx];
      const undoScore = score - lastScore;
      checkDouble(undoScore);
      handleUndo(score);
    },
    [userScoreHistory, legNo, handleUndo, checkDouble]
  );

  const navigateHome = () => {
    navigate("/");
  };

  const navigateGameOverview = () => {
    navigate(`/scoring-result/${token}`);
  };

  const navigateViewInEvents = () => {
    navigate(`/events`);
  };

  useEffect(() => {
    fetchUserScore();
  }, [fetchUserScore]);

  return (
    <div
      className="relative flex flex-col w-full h-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${images?.ARENAREPLYBG})` }}
    >
      <div className="absolute w-full h-full top-0 left-0 dark:bg-[#171927bb] bg-[#0C0D15aa] z-10"></div>
      <ScoringPageHeader
        legNo={legNo}
        isBullModalOpen={isBullModalOpen}
        setIsBackModal={setIsBackModal}
        setIsFinishModal={setIsFinishModal}
      />

      {isBullModalOpen ? (
        <BulloutCheck
          token={token}
          user={user}
          opponentUser={opponentUser}
          challenger={challenger}
          userWin={userWin}
          opponentWin={opponentWin}
          userTurn={userTurn}
          isKeyboardVisible={isKeyboardVisible}
          fetchUserScore={fetchUserScore}
        />
      ) : isFinished ? (
        <div className="relative flex flex-col items-center gap-2 sm:gap-4 z-10 mt-12 rounded-lg p-2 sm:p-8 mx-auto w-3/5">
          <div className="absolute w-full h-full top-0 left-0 dark:bg-[#c2c3cf3d] bg-[#c3c3c34a] rounded-lg z-10"></div>
          <p className="relative text-orange-500 font-bold text-3xl sm:text-6xl mb-4 sm:mb-8 z-10">
            Game Over
          </p>
          <button
            className="px-4 py-2 rounded-md text-md sm:text-2xl font-medium text-white bg-green-400 w-40 sm:w-72 cursor-pointer z-10"
            onClick={navigateHome}
          >
            Home
          </button>
          <button
            className="px-4 py-2 rounded-md text-md sm:text-2xl font-medium text-white bg-green-400 w-40 sm:w-72 cursor-pointer z-10"
            onClick={navigateGameOverview}
          >
            Game Overview
          </button>
          <button
            className="px-4 py-2 rounded-md text-md sm:text-2xl font-medium text-white bg-green-400 w-40 sm:w-72 cursor-pointer z-10"
            onClick={navigateViewInEvents}
          >
            View in Events
          </button>
        </div>
      ) : (
        <ScoringPlay
          user={user}
          opponentUser={opponentUser}
          userCurrentScore={userCurrentScore}
          opponentCurrentScore={opponentCurrentScore}
          userScoreHistory={userScoreHistory}
          opponentScoreHistory={opponentScoreHistory}
          userWin={userWin}
          opponentWin={opponentWin}
          legNo={legNo}
          userTurn={userTurn}
          isKeyboardVisible={isKeyboardVisible}
          isEnableKeyEvent={isEnableKeyEvent}
          isUndo={isUndo}
          isLoading={isLoading}
          isFinished={isFinished}
          handleSubmit={onSubmit}
          handleGameFinish={handleGameFinish}
          handleUndo={onUndo}
          onClickUndo={onClickUndo}
        />
      )}

      <button
        className={`fixed right-2 bottom-2 ${
          isKeyboardVisible ? "bg-red-500" : "bg-green-500"
        } w-10 h-10 flex items-center justify-center rounded-full visible sm:hidden z-10`}
        onClick={onChangeKeyboardVisible}
      >
        {isKeyboardVisible ? <X color="white" /> : <Keyboard color="white" />}
      </button>

      {isFinished && <AchievementModal isOpen={isFinished} token={token} />}
      {isModalOpen && (
        <ScoringModal
          isOpen={isModalOpen}
          modalType={modalType}
          possibleCheckArr={possibleCheckArr}
          possibleDoubleArr={possibleDoubleArr}
          onClose={() => setIsModalOpen(false)}
          handleSubmit={handleSubmitModal}
        />
      )}
      <ConfirmModal
        isOpen={isFinishModal}
        modalType={"warning"}
        content={"Are you sure you want to stop this fight?"}
        onClose={() => setIsFinishModal(false)}
        handleConfirm={handleFinishClick}
      />
      <ConfirmModal
        isOpen={isBackModal}
        modalType={"warning"}
        content={"Are you sure you want to back?"}
        onClose={() => setIsBackModal(false)}
        handleConfirm={handleBack}
      />
    </div>
  );
};

export default ScoringPage;
