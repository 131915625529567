import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { useConstant } from "../hooks/useConstant";
import { classNames, isVariableEmpty } from "../helper/helper";
// import { useSocketConnection } from "../hooks/useSoecketConnection";
import DiscordIcon from "../components/SVG/DiscordIcon";
import FacebookIcon from "../components/SVG/FacebookIcon";
import TwitterIcon from "../components/SVG/TwitterIcon";
import InstagramIcon from "../components/SVG/InstagramIcon";
import constant from "../helper/constant";

const ProfileLayout = () => {
  // useSocketConnection();
  const { profileMenuItems } = useConstant();
  const location = useLocation();
  const { pathname } = location;
  const auth = useSelector((state) => state.auth);

  return (
    <div className="relative flex flex-col items-center lg:items-start lg:flex-row lg:divide-x">
      <div className="w-full flex flex-col items-center justify-between lg:block sm:w-3/4 lg:w-1/4 sm:px-8 sm:py-4 m-4 px-4 py-2 lg:space-y-4 rounded-lg">
        <div className="p-4">
          <div className="flex justify-center items-center p-2 border rounded-lg">
            {isVariableEmpty(auth?.user?.avatar) ? (
              <img
                src="https://www.f-cdn.com/assets/main/en/assets/unknown.png?image-optimizer=force&format=webply&width=336 1x"
                className="rounded-lg w-20 lg:w-44"
                alt="user-avatar"
              />
            ) : (
              <img
                src={auth?.user?.avatar}
                className="rounded-lg w-20 lg:w-44"
                alt="user-avatar"
              />
            )}
          </div>
        </div>

        <div className="text-center font-bold uppercase text-2xl lg:text-sm xl:text-xl dark:bg-gray-800 text-black dark:text-white">
          {auth?.user?.username}
        </div>

        <div className="py-4 flex items-center justify-center gap-4 lg:mt-16">
          <a
            className={`mb-2 inline-block rounded-full bg-green-600 dark:bg-gray-900 p-2 text-xs font-medium uppercase leading-normal text-white shadow-lg transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
              auth?.user?.discord ? "" : "pointer-events-none opacity-50"
            }`}
            href={auth?.user?.discord}
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
          <a
            className={`mb-2 inline-block rounded-full bg-green-600 dark:bg-gray-900 p-2 text-xs font-medium uppercase leading-normal text-white shadow-lg transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
              auth?.user?.facebook ? "" : "pointer-events-none opacity-50"
            }`}
            href={auth?.user?.facebook}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            className={`mb-2 inline-block rounded-full bg-green-600 dark:bg-gray-900 p-2 text-xs font-medium uppercase leading-normal text-white shadow-lg transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
              auth?.user?.instgram ? "" : "pointer-events-none opacity-50"
            }`}
            href={auth?.user?.instgram}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            className={`mb-2 inline-block rounded-full bg-green-600 dark:bg-gray-900 p-2 text-xs font-medium uppercase leading-normal text-white shadow-lg transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${
              auth?.user?.twitter ? "" : "pointer-events-none opacity-50"
            }`}
            href={auth?.user?.twitter}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
        </div>

        {/* <motion.ul
          variants={constant.Animationcontainer}
          initial="hidden"
          animate="visible"
        >
          {profileMenuItems.map((item) => (
            <motion.li key={item.name} variants={constant.TopDownAnimationitem}>
              <Link
                key={item.name}
                to={item.to}
                className={classNames(
                  "group absolute block lg:hidden bg-green-500 dark:bg-gray-800 text-white gap-2 left-0 z-10 rounded-r-lg",
                  item.position
                )}
              >
                <div
                  className={classNames(
                    "relative flex items-center bg-green-500 dark:bg-gray-800 border px-4 py-2 rounded-r-lg",
                    item.width
                  )}
                >
                  {item.icon}
                  <div
                    className={classNames(
                      "absolute right-full text-left group-hover:left-12",
                      item.width
                    )}
                  >
                    {item.name}
                  </div>
                </div>
              </Link>
            </motion.li>
          ))}
        </motion.ul> */}

        {/* <motion.ul
          variants={constant.Animationcontainer}
          initial="hidden"
          animate="visible"
          className="hidden lg:block"
        >
          {profileMenuItems.map((item) => (
            <motion.li key={item.name} variants={constant.TopDownAnimationitem}>
              <Link
                to={item.to}
                className={classNames(
                  pathname.includes(item.current)
                    ? "text-white bg-green-500 dark:bg-gray-900"
                    : "text-black dark:text-white hover:text-white hover:bg-green-500 dark:hover:bg-gray-900",
                  "flex items-center justify-center lg:justify-start gap-2 text-left font-semibold text-lg p-2 transition-colors duration-200 rounded-lg "
                )}
                aria-current={
                  pathname.includes(item.current) ? "page" : undefined
                }
              >
                <span>{item.icon}</span>
                {item.name}
              </Link>
            </motion.li>
          ))}
        </motion.ul> */}
      </div>

      <div className="w-full lg:w-3/4 px-4 py-12 lg:px-8 lg:py-16">
        <Outlet />
      </div>
    </div>
  );
};

export default ProfileLayout;
