import { useState, useMemo } from "react";
import http from "../helper/http-client";

const useFightsMonth = () => {
  const [fightsMonth, setFightsMonth] = useState([]);

  useMemo(async () => {
    try {
      const response = await http.get("/event/fights-per-day-in-month");
      setFightsMonth(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return fightsMonth;
};

export default useFightsMonth;
