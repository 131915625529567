import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { handleGetJoinedUsers, handleGetMatchResults } from "../app/arenaSlice";
import { fetchUserInfo } from "../app/authSlice";
import http from "../helper/http-client";
import { debounce } from "../helper/helper";

const useArena = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [isStartLoading, setIsStartLoading] = useState(false);
  const [isOptionModal, setIsOptionModal] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const scrollPositionRef = useRef(0);

  const {
    joinedUsers = [],
    isLoading,
    matchResults = [],
    totalPages,
  } = useSelector((state) => state.arena);
  const { user } = useSelector((state) => state.auth);

  const handleLoadMore = () => {
    if (page < totalPages) setPage((prev) => prev + 1);
  };

  const handleOptionModal = (flag) => {
    setIsOptionModal(flag);
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  };

  const debouncedHandleSearch = debounce(handleSearch, 2000);

  const isVisibleLoadMoreBtn = useMemo(() => {
    return totalPages > page;
  }, [totalPages, page]);

  const startArena = async (option) => {
    setIsStartLoading(true);
    try {
      const res = await http.post(
        `/arena/match/${"Sparring"}/${user.username}`,
        { option }
      );

      if (res.data) {
        if (option === "Manual") {
          dispatch(fetchUserInfo());
          navigate(`/arena/reply`, {
            state: {
              match: res.data,
              isReply: false,
              message: res?.data?.message,
            },
          });
        } else {
          dispatch(fetchUserInfo());
          return;
        }
      }
    } catch (err) {
      toast.error(err?.data?.message);
      console.error(err);
    } finally {
      setIsStartLoading(false);
    }
  };

  const handleRefreshArean = async () => {
    try {
      setPage(1);
      dispatch(fetchUserInfo());
      dispatch(
        handleGetMatchResults({ arenaTitle: "Sparring", page: 1, limit: 9 })
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(handleGetJoinedUsers("Sparring"));
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    scrollPositionRef.current = window.scrollY;

    dispatch(handleGetMatchResults({ arenaTitle: "Sparring", page, limit: 9, username: searchStr }));
  }, [dispatch, page, searchStr]);

  useEffect(() => {
    // Restore the scroll position after the data has been updated
    window.scrollTo({
      top: scrollPositionRef.current,
      behavior: "smooth",
    });
  }, [matchResults]);

  return {
    joinedUsers,
    isLoading,
    matchResults,
    isStartLoading,
    totalPages,
    searchStr,
    isVisibleLoadMoreBtn,
    isOptionModal,
    handleLoadMore,
    startArena,
    handleRefreshArean,
    handleOptionModal,
    handleSearch: debouncedHandleSearch,
  };
};

export default useArena;
