import { Outlet } from "react-router";

const InfoLayout = () => {
  return (
    <div className="flex flex-col items-center md:items-start md:flex-row md:divide-x">
      <div className="w-full p-4 py-12 md:px-8 md:py-16">
        <Outlet />
      </div>
    </div>
  );
};

export default InfoLayout;
