import React from "react";
import { useTranslation } from "react-i18next";
import AnimatedCounter from "../AnimatedCounter";
import { formatStringDate } from "../../helper/helper";
import images from "../../helper/images";

const MostFightsCard = ({ season, lifetime }) => {
  const { t } = useTranslation();
  const { BGCOUNTER1 } = images;

  const seasonCount = season?.length ? season[0].count : 0;
  const seasonDate = season?.length ? formatStringDate(season[0]._id) : null;
  const lifetimeCount = lifetime?.length ? lifetime[0].count : 0;
  const lifetimeDate = lifetime?.length
    ? formatStringDate(lifetime[0]._id)
    : null;

  const data = [
    {
      title: t("season"),
      value: Number(seasonCount).toString().split(""),
      date: seasonDate,
    },
    {
      title: t("lifetime"),
      value: Number(lifetimeCount).toString().split(""),
      date: lifetimeDate,
    },
  ];

  return (
    <div
      className="relative w-full h-full text-white dark:text-white bg-cover bg-center pt-4 px-8 sm:px-8 xl:px-0 rounded-xl"
      style={{ backgroundImage: `url(${BGCOUNTER1})` }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-gray-900 from-10% to-[#56565600] to-100% rounded-xl"></div>
      {/* Header */}
      <h2 className="relative w-full text-3xl font-bold text-left px-4 mb-4 font-poppins">
        {t("mostFightsInADay")}
      </h2>
      {/* Body */}
      <div className="p-2 relative flex flex-col gap-4">
        {data?.map((item, index) => (
          <div
            key={index}
            className="flex flex-col sm:flex-row sm:items-center gap-2 px-4 sm:px-0"
          >
            <p
              className={`w-32 py-1 px-3 text-2xl text-left font-semibold uppercase`}
            >
              {item.title}
            </p>
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-1 bg-white rounded-xl">
                {item.value.length &&
                  item.value.map((item, index) => (
                    <div className="relative w-10 h-14 text-black text-5xl font-bold rounded-xl font-bebas-neue ">
                      <AnimatedCounter
                        key={index}
                        className={
                          "absolute top-[32px] left-1/2 -translate-x-1/2 -translate-y-1/2"
                        }
                        targetValue={parseInt(item)}
                        duration={1000}
                      />
                    </div>
                  ))}
              </div>
              <p className="flex-1 font-bebas-neue text-2xl text-left font-medium">
                <span className="font-mono">(</span>
                {item.date}
                <span className="font-mono">)</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MostFightsCard;
