import AnimatedCounter from "../AnimatedCounter";

const HomeCounter = ({ title, data, BGCOUNTER }) => {
  return (
    <div
      className="relative w-full h-full py-6 px-8 bg-cover bg-center rounded-xl font-poppins uppercase shadow-md"
      style={{ backgroundImage: `url(${BGCOUNTER})` }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-gray-900 from-10% to-[#56565600] to-100% rounded-xl"></div>
      <p className="relative w-full text-white text-3xl font-bold mb-4">
        {title}
      </p>

      <div className="relative w-full flex items-center justify-center gap-4">
        {data.length &&
          data.map((item, index) => (
            <div className="relative w-10 h-14 bg-white text-black text-5xl font-bold rounded-xl font-bebas-neue ">
              <AnimatedCounter
                key={index}
                className={
                  "absolute top-[32px] left-1/2 -translate-x-1/2 -translate-y-1/2"
                }
                targetValue={parseInt(item)}
                duration={1000}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default HomeCounter;
