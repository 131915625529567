import React, { useCallback } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

import Loading from "../Loading";
import OnlineCheck from "../OnlineCheck";
import SearchBar from "./SearchBar";

const PyramidMenu = ({
  className,
  connectedUsers,
  isOnlineShow,
  setIsOnlineShow,
  setSelectedPlayer,
}) => {
  const { results: players, isLoading } = useSelector((state) => state.result);

  className = clsx("", className);

  const onPlayerClick = useCallback(
    (payload) => {
      setSelectedPlayer(payload);
    },
    [setSelectedPlayer]
  );

  return (
    <div className={className}>
      <OnlineCheck
        isOnlineShow={isOnlineShow}
        setIsOnlineShow={setIsOnlineShow}
      />
      {isLoading ? (
        <div className="flex flex-col gap-4">
          <Loading />
          <Loading />
          <Loading />
        </div>
      ) : (
        <SearchBar
          players={players}
          onPlayerClick={onPlayerClick}
          connectedUsers={connectedUsers}
          isOnlineShow={isOnlineShow}
        />
      )}
    </div>
  );
};

export default React.memo(PyramidMenu);
