import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import images from "../../helper/images";

const HomeSlider = () => {
  const { HOMESLIDER1, HOMESLIDER2, HOMESLIDER3, HOMESLIDER4 } = images;

  const slides = [
    {
      image: HOMESLIDER1,
      text: "Dart Fight Club is an online platform where dart enthusiasts can challenge each other to friendly competitions. Whether you’re a seasoned pro or a beginner, our platform allows you to hone your skills, challenge friends, and keep track of your progress in the world of darts.",
      title: "Introduction to Dart Fight Club",
    },
    {
      image: HOMESLIDER2,
      text: 'Open the Dart Fight Club App: Navigate to the “Challenges” section. Choose a friend or let the app pick a random opponent. Set your preferred game type, number of rounds, and scoring method. Tap "Send" and wait for your opponent to accept.',
      title: "How to Send a Quick Challenge",
    },
    {
      image: HOMESLIDER3,
      text: "Click the “Scheduled Challenges” button. Pick a suitable time for both you and your opponent. Send the challenge to your chosen opponent and wait for their confirmation.",
      title: "How to Send a Scheduled Challenge",
    },
    {
      image: HOMESLIDER4,
      text: "Once the match concludes, both players will see the final score. Both players must confirm the match result on their screens. The result is then saved to your profile, and rankings are updated automatically.",
      title: "How to Save the Fight Result",
    },
  ];

  const multipleElipsisCSS = {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      slidesPerView={"auto"}
      loop={true}
      grabCursor={true}
      modules={[Pagination, Autoplay]}
      className="rounded-2xl"
    >
      {slides.map((slide, index) => (
        <SwiperSlide
          key={index}
        >
          <div
            key={index}
            className="relative flex w-full h-full sm:64 md:h-72 lg:h-80 bg-cover bg-center rounded-2xl"
            style={{ backgroundImage: `url(${slide.image})` }}
          >
            <div className="absolute top-0 w-full h-full bg-gradient-to-br from-black from-10% to-[#56565600] to-100% rounded-3xl"></div>
            <div className="relative flex flex-col justify-center w-full top-0 text-white p-4 sm:p-12 font-poppins">
              <p className="text-xl sm:text-2xl md:text-3xl lg:text-5xl font-bold mb-8 truncate text-left">
                {slide.title}
              </p>
              <p className="text-left" style={multipleElipsisCSS}>
                {slide.text}
              </p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomeSlider;
