import socket from "../socket";

export const handleFinishSocketEvent = (token, opponentUser) => {
  socket.emit("match-finish", { token, opponentId: opponentUser._id });
};

export const handleScoreUpdateSocketEvent = (
  score,
  missed,
  thrown,
  toFinish,
  token,
  isUndo,
  user,
  opponentUser
) => {
  socket.emit("score-update", {
    user: user.username,
    userId: user._id,
    opponent: opponentUser.username,
    opponentId: opponentUser._id,
    score,
    missed,
    thrown,
    isUndo,
    toFinish,
    token,
  });
};

export const handleBullChallengerFinishSocketEvent = (
  score,
  token,
  user,
  opponentUser
) => {
  socket.emit("bull-challenger-finish", {
    username: user.username,
    opponentId: opponentUser._id,
    score,
    token,
  });
};

export const handleBullScoreSocketEvent = (
  score,
  token,
  user,
  opponentUser
) => {
  socket.emit("bull-score", {
    username: user.username,
    opponentId: opponentUser._id,
    score,
    token,
  });
};

export const handleBullUpdateSocketEvent = (score, user, opponentUser) => {
  socket.emit("bull-update", {
    username: user.username,
    opponentId: opponentUser._id,
    score,
  });
};
