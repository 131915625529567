import React, { useState, useEffect, useRef } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

const CustomDropdown = ({ options, initialValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    if (onChange) {
      onChange(value);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block w-52">
      <button
        onClick={toggleDropdown}
        className="relative bg-gray-100 dark:bg-gray-800 dark:text-white border-none rounded-md py-2 px-4 cursor-pointer w-full text-left"
      >
        {options.find((option) => option.value === selectedValue)?.label ||
          "Select an option"}
        {isOpen ? (
          <ChevronUpIcon className="h-5 w-5 absolute right-4 top-1/2 transform -translate-y-1/2" />
        ) : (
          <ChevronDownIcon className="h-5 w-5 absolute right-4 top-1/2 transform -translate-y-1/2" />
        )}
      </button>
      {isOpen && (
        <ul className="absolute left-0 w-full bg-gray-100 dark:bg-gray-800 dark:text-white border-none rounded-md py-2 mt-1 z-10">
          {options.map((option) => (
            <li
              key={option.value}
              className={`py-2 px-4 cursor-pointer ${
                selectedValue === option.value
                  ? "bg-gray-300 dark:bg-gray-700"
                  : ""
              }`}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
