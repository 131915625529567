import { useState, useMemo } from "react";
import http from "../helper/http-client";

const useFetchFights = () => {
  const [totalFights, setTotalFights] = useState(null);

  useMemo(async () => {
    try {
      const response = await http.get(`/statistic/get`);
      setTotalFights(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    totalFights,
  };
};

export default useFetchFights;
