import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useConstant } from "../../hooks/useConstant";
import { classNames, isVariableEmpty } from "../../helper/helper";

const HeaderProfileDropdown = ({ userAvatar, userName, handleLogout }) => {
  const { t } = useTranslation();
  const { userMenuItems } = useConstant();
  return (
    <Menu as="div" className="relative ml-2 sm:ml-6 lg:ml-12">
      <div>
        <Menu.Button className="flex items-center gap-4">
          <div className="relative flex rounded-full text-sm border dark:border-gray-700 p-2">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">{t("openUserMenu")}</span>
            {isVariableEmpty(userAvatar) ? (
              <img
                className="h-8 w-8 rounded-full"
                src="https://www.f-cdn.com/assets/main/en/assets/unknown.png?image-optimizer=force&format=webply&width=336 1x"
                alt=""
              />
            ) : (
              <img
                src={userAvatar}
                alt=""
                className="h-8 w-8 flex-none rounded-lg dark:bg-gray-800 object-cover"
              />
            )}
          </div>
          <div className="text-lg font-semibold text-gray-700 dark:text-gray-200 hidden sm:block">
            {userName}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userMenuItems.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <Link
                  to={item.to}
                  className={classNames(
                    active ? "bg-gray-100 dark:bg-gray-900" : "",
                    item.to === '/price' ? 'text-yellow-500 dark:text-yellow-300' : '',
                    "block px-4 py-2 text-sm text-gray-700 dark:bg-gray-800 dark:text-gray-200"
                  )}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={handleLogout}
                className={classNames(
                  active ? "bg-gray-100 dark:bg-gray-900" : "",
                  "block px-4 py-2 text-sm w-full text-gray-700 dark:bg-gray-800 dark:text-gray-200"
                )}
              >
                {t("signOut")}
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default HeaderProfileDropdown;
