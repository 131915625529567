import AchievementImages from "./images";
import handleAchievements from "./achievements";

const cntAchievements = (result) => {
  let achievementsCnt = 0;

  achievementsCnt += AchievementImages.PYRAMIDCLIMBER.map((item, index) =>
    handleAchievements.pyramidClimber(index, result?.pyramidClimber?.lifetime)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.CHALLENGECONQUEROR.map((item, index) =>
    handleAchievements.challengeConqueror(
      index,
      result?.challengeConqueror?.lifetime
    )
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.MAXVICTORYSTREAK.map((item, index) =>
    handleAchievements.streakActive(index, result?.maxVictoryStreak)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.MONTHLYMAESTRO.map((item, index) =>
    handleAchievements.monthlyMaestro(index, result?.monthlyMaestro?.lifetime)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.PYRAMIDPROTECTOR.map((item, index) =>
    handleAchievements.pyramidProtector(index, result?.pyramidProtector?.lifetime)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.IRONDART.map((item, index) =>
    handleAchievements.ironDart(index, result?.ironDart?.lifetime)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.MASTER180.map((item, index) =>
    handleAchievements.master180(index, result?.master180?.lifetime)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.CONSISTENTSCORER.map((item, index) =>
    handleAchievements.consistentScorer(index, result?.consistentScorer?.lifetime)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.MASTER26.map((item, index) =>
    handleAchievements.breakfastActive(index, result?.master26?.lifetime)
  )?.filter((item) => item).length;

  achievementsCnt += handleAchievements.cntGrandMaster(
    result?.grandMaster?.lifetime?.match
  );
  achievementsCnt += handleAchievements.cntGrandMaster(
    result?.grandMaster?.lifetime?.leg
  );
 
  // achievementsCnt += result?.maxMarksman ? 1 : 0;

  achievementsCnt += result?.highFinish?.filter((item) => item).length;

  achievementsCnt += AchievementImages.DARTENTHUSIAST.map((item, index) =>
    handleAchievements.dartEnthusiast(index, result?.dartEnthusiast?.lifetime)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.FRIENDLYCHALLENGER.map((item, index) =>
    handleAchievements.friendlyActive(index, result?.friendlyChallenger?.lifetime)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.READYFORIT.map((item, index) =>
    handleAchievements.readyForIt(index, result?.readyForIt?.lifetime)
  )?.filter((item) => item).length;

  achievementsCnt += result?.championChallenger ? 1 : 0;

  return achievementsCnt;
};

const cntSeasonAchievements = (result) => {
  let achievementsCnt = 0;

  achievementsCnt += AchievementImages.PYRAMIDCLIMBER.map((item, index) =>
    handleAchievements.pyramidClimber(index, result?.pyramidClimber?.season)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.CHALLENGECONQUEROR.map((item, index) =>
    handleAchievements.challengeConqueror(
      index,
      result?.challengeConqueror?.season
    )
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.MAXVICTORYSTREAK.map((item, index) =>
    handleAchievements.streakActive(index, result?.seasonMaxVictoryStreak)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.MONTHLYMAESTRO.map((item, index) =>
    handleAchievements.monthlyMaestro(index, result?.monthlyMaestro?.season)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.PYRAMIDPROTECTOR.map((item, index) =>
    handleAchievements.pyramidProtector(index, result?.pyramidProtector?.season)
  )?.filter((item) => item)?.length;

  achievementsCnt += AchievementImages.IRONDART.map((item, index) =>
    handleAchievements.ironDart(index, result?.ironDart?.season)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.MASTER180.map((item, index) =>
    handleAchievements.master180(index, result?.master180?.season)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.CONSISTENTSCORER.map((item, index) =>
    handleAchievements.consistentScorer(index, result?.consistentScorer?.season)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.MASTER26.map((item, index) =>
    handleAchievements.breakfastActive(index, result?.master26?.season)
  )?.filter((item) => item).length;

  achievementsCnt += handleAchievements.cntGrandMaster(
    result?.grandMaster?.season?.match
  );
  achievementsCnt += handleAchievements.cntGrandMaster(
    result?.grandMaster?.season?.leg
  );

  // achievementsCnt += result?.maxMarksman ? 1 : 0;

  achievementsCnt += result?.highFinish?.filter((item) => item).length;

  achievementsCnt += AchievementImages.DARTENTHUSIAST.map((item, index) =>
    handleAchievements.dartEnthusiast(index, result?.dartEnthusiast?.season)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.FRIENDLYCHALLENGER.map((item, index) =>
    handleAchievements.friendlyActive(index, result?.friendlyChallenger?.season)
  )?.filter((item) => item).length;

  achievementsCnt += AchievementImages.READYFORIT.map((item, index) =>
    handleAchievements.readyForIt(index, result?.readyForIt?.season)
  )?.filter((item) => item).length;

  // achievementsCnt += result?.championChallenger ? 1 : 0;

  return achievementsCnt;
};

const setTotalAchievements = (result) => {
  const arr = [5, 10, 15, 20, 25];
  if (result >= 25) return 25;
  else if (result < 5) return 0;
  else
    for (let i = 0; i < arr.length - 1; i++) {
      if (result >= arr[i] && result < arr[i + 1]) return arr[i];
    }
};

const calculateCurrentMonthAverages = (objArray) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns zero-based month (0-11)

  const filteredObjects = objArray?.filter((obj) => {
    const createdAt = new Date(obj.date);
    const createdMonth = createdAt.getMonth() + 1;
    return createdMonth === currentMonth;
  });

  if (filteredObjects?.length === 0) {
    return {
      matchAvg: 0,
      first9Avg: 0,
      doubles: 0,
    }; // return empty object if there are no objects in the current month
  }

  const sum = {};
  const count = filteredObjects?.length;

  filteredObjects?.forEach((obj) => {
    for (const property in obj) {
      if (property !== "_id" && property !== "date") {
        if (sum.hasOwnProperty(property)) {
          sum[property] += obj[property];
        } else {
          sum[property] = obj[property];
        }
      }
    }
  });

  const averages = {};
  for (const property in sum) {
    averages[property] = parseFloat(sum[property] / count).toFixed(2);
  }

  return averages;
};

const transformSummaryData = (data) => {
  const filteredData = data?.summary?.filter(
    (item) => item.doubles !== 0 && item.first9Avg !== 0 && item?.matchAvg !== 0
  );

  if (filteredData?.length === 0)
    return {
      overall: { doubles: 0, first9Avg: 0, matchAvg: 0, fights: 0 },
      season: { doubles: 0, first9Avg: 0, matchAvg: 0, fights: 0 },
    };

  let avgDoubles = 0,
    avgFirst9Avg = 0,
    avgMatchAvg = 0;

  filteredData?.map((item) => {
    avgDoubles += item.doubles;
    avgFirst9Avg += item.first9Avg;
    avgMatchAvg += item?.matchAvg;
  });

  avgDoubles = parseFloat(avgDoubles / filteredData?.length).toFixed(2);
  avgFirst9Avg = parseFloat(avgFirst9Avg / filteredData?.length).toFixed(2);
  avgMatchAvg = parseFloat(avgMatchAvg / filteredData?.length).toFixed(2);

  return {
    overall: {
      doubles: avgDoubles,
      first9Avg: avgFirst9Avg,
      matchAvg: avgMatchAvg,
      fights: data?.friendlyChallenger?.lifetime + data?.readyForIt?.lifetime,
    },
    season: {
      ...calculateCurrentMonthAverages(filteredData),
      fights: data?.friendlyChallenger?.season + data?.readyForIt?.season,
    },
  };
};

export {
  cntAchievements,
  cntSeasonAchievements,
  setTotalAchievements,
  transformSummaryData,
};
