import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { handleChangePassword, handleUserUpdate } from "../app/authSlice";
import { useAuth } from "./useAuth";

export const useSettings = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const { isLoading, user, error } = authState;
  const { handleAvatar } = useAuth();

  const [preview, setPreview] = useState(user?.avatar);
  const [userData, setUserData] = useState({
    username: authState.user?.username,
    email: authState.user?.email,
    discord: authState.user?.discord,
    facebook: authState.user?.facebook,
    twitter: authState.user?.twitter,
    instagram: authState.user?.instagram,
  });
  const [pwdInfo, setPwdInfo] = useState({});

  const handleSettingAvatar = async (e) => {
    e.preventDefault();
    const url = await handleAvatar(e);
    setPreview(url);
  };

  const onUserDataChange = (e) => {
    setUserData({ ...userData, ...e });
  };

  const handleUserInfo = async (e) => {
    e.preventDefault();
    dispatch(handleUserUpdate({ ...userData, avatar: preview }));
  };

  const onChangePwd = (e) => {
    setPwdInfo({ ...pwdInfo, ...e });
  };

  const handlePwdChange = async (e) => {
    e.preventDefault();
    if (pwdInfo.newpassword !== pwdInfo.confirmpassword)
      return toast.warning("New password and confirm password must match!");
    dispatch(
      handleChangePassword({
        currentPassword: pwdInfo.currentpassword,
        newPassword: pwdInfo.newpassword,
      })
    );
  };

  return {
    isLoading,
    preview,
    userData,
    error,
    handleSettingAvatar,
    onUserDataChange,
    handleUserInfo,
    onChangePwd,
    handlePwdChange,
  };
};
