import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const useHandleOpenGames = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleJoinGame = useCallback(
    (game) => {
      const opponent =
        game?.p1?.name === user.username ? game?.p2?.name : game?.p1?.name;
      navigate(
        `/scoring/${opponent?.toLowerCase()}/${game?.p1?.name?.toLowerCase()}/${
          game?.token
        }`
      );
    },
    [navigate, user.username]
  );

  return {
    user,
    handleJoinGame,
  };
};
