import CustomModal from "../CustomModal";
import SpinnerLoading from "../SpinnerLoading";

const ArenaWaitingModal = ({ isShow, text }) => {
  return (
    <CustomModal isOpen={isShow} onClose={() => {}}>
      <div>
        <SpinnerLoading />
        <p className="bg-white dark:bg-gray-800 text-black dark:text-white text-center mb-4 mt-8">
          {text}
        </p>
      </div>
    </CustomModal>
  );
};

export default ArenaWaitingModal;
