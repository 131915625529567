import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import ArenaAvatarComponent from "../../components/Arena/ArenaAvatarComponent";
import ArenaMatchResults from "../../components/Arena/ArenaMatchResults";
import ScrollToUpBtn from "../../components/ScrollToUpBtn";
import useArena from "../../hooks/useArena";
import Loading from "../../components/Loading";
import ArenaWaitingModal from "../../components/Arena/ArenaWaitingModal";
import ArenaFightMethodModal from "../../components/Arena/ArenaFightMethodModal";
import InputComponent from "../../components/InputComponent";

const SparringPage = () => {
  const {
    joinedUsers,
    isLoading,
    isStartLoading,
    matchResults,
    isVisibleLoadMoreBtn,
    isOptionModal,
    handleLoadMore,
    startArena,
    handleOptionModal,
    handleSearch,
  } = useArena();

  return (
    <div className="px-8 py-16 text-black dark:text-white">
      <h1 className="font-bold text-6xl mb-12 uppercase">Sparring</h1>
      <div className="flex px-4 mb-8 items-center">
        {/* <button
          className="mr-auto px-4 py-2 border border-yellow-400 bg-green-500 hover:bg-green-600 dark:bg-gray-500 dark:hover:bg-gray-600 text-white rounded-md font-semibold"
          onClick={handleRefreshArean}
        >
          <ArrowPathIcon className="h-6 w-6" aria-hidden="true" />
        </button> */}
        <InputComponent
          name="search"
          onChange={handleSearch}
          icon={<MagnifyingGlassIcon className="w-6 h-6" aria-hidden="true" />}
        />
        <button
          className={`ml-auto px-4 py-2 border border-yellow-400 bg-green-500 hover:bg-green-600 dark:bg-gray-500 dark:hover:bg-gray-600 text-white rounded-md font-semibold ${
            isStartLoading ? "cursor-not-allowed" : ""
          }`}
          onClick={() => handleOptionModal(true)}
        >
          Fight
        </button>
      </div>
      {isLoading ? (
        <div className="flex flex-col gap-4">
          <Loading />
          <Loading />
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <ArenaAvatarComponent users={joinedUsers} />
          <ArenaMatchResults
            matchResults={matchResults}
            isVisibleLoadMoreBtn={isVisibleLoadMoreBtn}
            handleLoadMore={handleLoadMore}
          />
        </div>
      )}
      <ArenaWaitingModal isShow={isStartLoading} text="Starting Arena..." />
      <ArenaFightMethodModal
        isShow={isOptionModal}
        onClose={() => handleOptionModal(false)}
        handleArenaFight={startArena}
      />
      <ScrollToUpBtn />
    </div>
  );
};

export default SparringPage;
