import { useParams } from "react-router";
import { HomeIcon } from "@heroicons/react/24/solid";
import { useScoringFightResult } from "../hooks/Scoring/useScoringFightResult";
import OwnFightResult from "../components/Result/OwnFightResult";
import Tabs from "../components/Tabs";
import ResultHeader from "../components/Result/ResultHeader";
import ResultScores from "../components/Result/ResultScores";
import ResultAchievements from "../components/Result/ResultAchievements";

const ScoringResultPage = () => {
  const { token } = useParams();

  const { isLoading, fightResult, winUser, loseUser, handleGoBack } =
    useScoringFightResult(token);

  const tabs = [
    {
      label: "Overview",
      content: <OwnFightResult isLoading={isLoading} result={fightResult} />,
    },
    {
      label: "Scores",
      content: (
        <ResultScores
          p1_name={fightResult?.p1?.name}
          p2_name={fightResult?.p2?.name}
          p1_scores={fightResult?.p1?.scoreHistory}
          p2_scores={fightResult?.p2?.scoreHistory}
        />
      ),
    },
    {
      label: "Achievements",
      content: <ResultAchievements winUser={winUser} loseUser={loseUser} />,
    },
  ];

  return (
    <div className="px-4 py-16">
      <div className="flex flex-col sm:flex-row items-center sm:items-start px-8 z-10 gap-4">
        <button className="border border-white bg-green-500 hover:border-green-700 dark:bg-gray-900 dark:hover:bg-gray-800 rounded-md py-2 px-4 ml-auto mr-0 sm:mr-4 text-white font-semibold">
          <HomeIcon className="w-6 h-6" onClick={handleGoBack} />
        </button>
      </div>
      <ResultHeader result={fightResult} />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default ScoringResultPage;
