import { useTranslation } from "react-i18next";

import CustomModal from "./CustomModal";
import SpinnerLoading from "./SpinnerLoading";

const WaitingModal = ({ isShow, text, challenger, receiver, handleCancel }) => {
  const { t } = useTranslation();
  return (
    <CustomModal isOpen={isShow} onClose={() => {}}>
      <div>
        <SpinnerLoading />
        <p className="bg-white dark:bg-gray-800 text-black dark:text-white text-center mb-4 mt-8">
          {text}
        </p>
        <div className="flex justify-end">
          <button
            className="bg-green-400 dark:bg-gray-900 hover:bg-green-500 dark:hover:bg-gray-700 text-white px-4 py-2 rounded-md"
            onClick={() => {
              handleCancel(challenger, receiver);
            }}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default WaitingModal;
