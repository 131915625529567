import { useState, useCallback, useEffect, useMemo } from "react";
import { handleBullUpdateSocketEvent } from "../../socket/scoringSocketEvent";
import { checkTurn } from "../../helper/scoring/utils";

const useDetermineTurn = (user, opponentUser, challenger, initTurn) => {
  const [turn, setTurn] = useState(initTurn);
  const [scoreHistory, setScoreHistory] = useState([]);
  const [currentInputScore, setCurrentInputScore] = useState(null);

  const isChallenger = useMemo(
    () => (user?.username === challenger ? true : false),
    [user, challenger]
  );

  const submitScore = useCallback(
    (score) => {
      if (!turn) return;
      setScoreHistory((prev) => [...prev, score]);
      setCurrentInputScore(score);
      handleBullUpdateSocketEvent(score, user, opponentUser);
    },
    [turn, user, opponentUser]
  );

  const handleSetTurn = useCallback((value) => {
    setTurn(value);
  }, []);

  useEffect(() => {
    const length = scoreHistory.length;
    const userTurn = checkTurn(length, isChallenger);
    handleSetTurn(userTurn);
  }, [scoreHistory, isChallenger, handleSetTurn]);

  return {
    turn,
    currentInputScore,
    scoreHistory,
    submitScore,
    handleSetTurn,
  };
};

export default useDetermineTurn;
