import { useState, useCallback, useEffect } from "react";
import { getMatchStatus } from "../../api/scoring.api";

const useFetchScoring = (token, user) => {
  const [matchStatus, setMatchStatus] = useState(null);
  const [userCurrentScore, setUserCurrentScore] = useState(501);
  const [opponentCurrentScore, setOpponentCurrentScore] = useState(501);
  const [userScoreHistory, setUserScoreHistory] = useState([]);
  const [opponentScoreHistory, setOpponentScoreHistory] = useState([]);
  const [userWin, setUserWin] = useState(0);
  const [opponentWin, setOpponentWin] = useState(0);
  const [legNo, setLegNo] = useState(1);
  const [userTurn, setUserTurn] = useState(true);
  const [isBullModalOpen, setIsBullModalOpen] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const resetScores = useCallback(() => {
    setUserCurrentScore(501);
    setOpponentCurrentScore(501);
    setUserScoreHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      newHistory[legNo] = [];
      return newHistory;
    });
    setOpponentScoreHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      newHistory[legNo] = [];
      return newHistory;
    });
  }, [
    legNo,
    setOpponentCurrentScore,
    setOpponentScoreHistory,
    setUserCurrentScore,
    setUserScoreHistory,
  ]);

  const fetchUserScore = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getMatchStatus(token);
      if (data) {
        setMatchStatus(data);
        setLegNo(data.legNo);
        setIsFinished(data.isFinished);
        // setIsBullModalOpen(data.bullModal);
        if (user.username === data.p1?.name) {
          setUserCurrentScore(data.p1.currentScore);
          setOpponentCurrentScore(data.p2.currentScore);
          setUserScoreHistory(
            data.p1.scoreHistory.map((score) => score?.scores)
          );
          setOpponentScoreHistory(
            data.p2.scoreHistory.map((score) => score?.scores)
          );
          setUserWin(data.p1.legs_won);
          setOpponentWin(data.p2.legs_won);
          setUserTurn(data.challengerTurn);
          setIsBullModalOpen(!data.p1.bull.isClosed);
        } else {
          setUserCurrentScore(data.p2.currentScore);
          setOpponentCurrentScore(data.p1.currentScore);
          setUserScoreHistory(
            data.p2.scoreHistory.map((score) => score?.scores)
          );
          setOpponentScoreHistory(
            data.p1.scoreHistory.map((score) => score?.scores)
          );
          setUserWin(data.p2.legs_won);
          setOpponentWin(data.p1.legs_won);
          setUserTurn(!data.challengerTurn);
          setIsBullModalOpen(!data.p2.bull.isClosed);
        }
      } else {
        setUserTurn(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [token, user.username]);

  useEffect(() => {
    setTimeout(() => {
      fetchUserScore();
    }, 2000);
  }, []);

  return {
    matchStatus,
    userCurrentScore,
    opponentCurrentScore,
    userScoreHistory,
    opponentScoreHistory,
    userWin,
    opponentWin,
    legNo,
    userTurn,
    isBullModalOpen,
    isFinished,
    isLoading,
    resetScores,
    setMatchStatus,
    setLegNo,
    setUserCurrentScore,
    setOpponentCurrentScore,
    setUserScoreHistory,
    setOpponentScoreHistory,
    setUserWin,
    setOpponentWin,
    setUserTurn,
    setIsBullModalOpen,
    setIsFinished,
    fetchUserScore,
    setIsLoading,
  };
};

export default useFetchScoring;
