import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { fetchResultAll } from "../app/resultSlice";

import useMostFights from "../hooks/useMostFights";
import useFightsMonth from "../hooks/useFightsMonth";

import HomeUsersTable from "../components/Home/HomeUsersTable";
import HomeTopMembers from "../components/Home/HomeTopMembers";
import HomeSlider from "../components/Home/HomeSlider";
import HomeCounter from "../components/Home/HomeCounter";
import UsersChart from "../components/Home/UsersChart";
import FightStatistics from "../components/Home/FightStatistics";
import RecentEvent from "../components/Home/RecentEvent";
import MostFightsCard from "../components/Home/MostFightsCard";
import images from "../helper/images";
import HomeTopSection from "../components/Home/HomeTopSection";
import useFetchFights from "../hooks/useFetchFights";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { BGCOUNTER1, BGCOUNTER2 } = images;

  const { totalFights } = useFetchFights();

  const { season: seasonMostFights, lifetime: lifetimeMostFights } =
    useMostFights();
  const fights = useFightsMonth();

  const seasonCounter = useMemo(
    () => (totalFights ? totalFights.season.toString().split("") : []),
    [totalFights]
  );
  const lifetimeCounter = useMemo(
    () => (totalFights ? totalFights.lifetime.toString().split("") : []),
    [totalFights]
  );

  useEffect(() => {
    dispatch(fetchResultAll());
  }, [dispatch]);

  return (
    <div className="flex flex-col pb-12">
      <div className="mt-12 px-8 relative flex flex-wrap 2xl:flex-nowrap gap-4">
        <div className="w-full lg:w-[48%] 2xl:w-[23%]">
          <HomeCounter
            title={t("seasonFights")}
            data={seasonCounter}
            BGCOUNTER={BGCOUNTER1}
          />
        </div>
        <div className="w-full lg:w-[48%] 2xl:w-[23%]">
          <HomeCounter
            title={t("lifetimeFights")}
            data={lifetimeCounter}
            BGCOUNTER={BGCOUNTER2}
          />
        </div>
        <div className="w-full lg:w-[60%] 2xl:w-[30%]">
          <MostFightsCard totalFights={totalFights} />
        </div>
        <div className="w-full lg:w-[36%] 2xl:w-[20%]">
          <HomeTopSection />
        </div>
      </div>
      <div className="flex gap-4 p-8">
        <HomeSlider />
      </div>
      <div className="mb-8 px-8">
        <FightStatistics fights={fights} />
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap px-4">
        <div className="w-full lg:w-1/2 p-4">
          <HomeTopMembers />
          <RecentEvent />
        </div>
        <div className="w-full lg:w-1/2">
          <HomeUsersTable />
          <UsersChart />
        </div>
      </div>
    </div>
  );
};

export default Home;
