import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { convertAchievementName } from "../helper/helper";
import AchievementImages from "../helper/images";
import ToolTip from "../components/ToolTip/ToolTip";

export const useTableColumns = () => {
  const { t } = useTranslation();

  const handleProfile = (username) => {
    window.location.href = `/profile/${username}`;
  };

  const eventColumns = [
    {
      id: "date",
      name: t("date"),
      selector: (row) => row.date,
      sortable: true,
      grow: 1,
      style: {
        fontSize: "16px",
      },
    },
    {
      name: t("content"),
      selector: (row) => row.id,
      sortable: false,
      grow: 4,
      cell: (row) => (
        <div>
          {row.type === "login" ? (
            <Link to={`/profile/${row.user}`}>
              {row.user}&nbsp;logged&nbsp;in
            </Link>
          ) : row.type === "logout" ? (
            <Link to={`/profile/${row.user}`}>
              {row.user}&nbsp;logged&nbsp;out
            </Link>
          ) : row.type === "register" ? (
            <Link to={`/profile/${row.user}`}>
              {row.user}&nbsp;registered&nbsp;in the system
            </Link>
          ) : row.type === "match" ? (
            <>
              <div>
                <Link to={`/profile/${row.user}`}>
                  {row.user}&nbsp;played&nbsp;{row?.match?.user1Won} :&nbsp;
                  {row?.match?.user2Won} against&nbsp;
                </Link>
                <Link to={`/profile/${row?.target}`}>{row?.target}&nbsp;</Link>
                <a
                  href={row?.match?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (&nbsp;match overview:&nbsp;{row?.match?.link} )
                </a>
              </div>
              <div className="flex flex-wrap gap-4">
                {row?.match?.achievements
                  .filter(
                    (val) =>
                      !(
                        val?.name.includes("lifetime") ||
                        val?.name.includes("season")
                      )
                  )
                  .map((val, index) => (
                    <div key={index} className="flex relative gap-2 my-2">
                      <ToolTip text={convertAchievementName(val?.name)}>
                        <div className="flex items-center">
                          <img
                            className="w-8 h-6"
                            src={
                              AchievementImages[
                                val?.name.toUpperCase().split(".")[0]
                              ][val?.index]
                            }
                            alt="achievement-icon"
                          />
                        </div>
                      </ToolTip>
                      <p className="flex items-center">&#x2715;</p>
                      <p className="flex items-center">{val?.value}</p>
                    </div>
                  ))}
                {row?.match?.achievements
                  .filter((val) => val?.name.includes("lifetime"))
                  .map((val, index) => (
                    <div key={index} className="flex gap-2 my-2">
                      <ToolTip text={convertAchievementName(val?.name)}>
                        <div className="flex items-center">
                          <img
                            className="w-8 h-6"
                            src={
                              AchievementImages[
                                val?.name.toUpperCase().split(".")[0]
                              ][val?.index]
                            }
                            alt="achievement-icon"
                          />
                        </div>
                      </ToolTip>
                      <p className="flex items-center">&#x2715;</p>
                      <p className="flex items-center">{val?.value}</p>
                    </div>
                  ))}
                {row?.match?.achievements
                  .filter((val) => val?.name.includes("season"))
                  .map((val, index) => (
                    <div key={index} className="flex gap-2 my-2">
                      <ToolTip text={convertAchievementName(val?.name)}>
                        <div className="flex items-center">
                          <img
                            className="w-8 h-6"
                            src={
                              AchievementImages[
                                val?.name
                                  .toUpperCase()
                                  .replace("SEASON", "")
                                  .split(".")[0]
                              ][val?.index]
                            }
                            alt="achievement-icon"
                            title={convertAchievementName(val?.name)}
                          />
                        </div>
                      </ToolTip>
                      <p className="flex items-center">&#x2715;</p>
                      <p className="flex items-center">{val?.value}</p>
                    </div>
                  ))}
              </div>
            </>
          ) : row.type === "decline" ? (
            <>
              <Link to={`/profile/${row.user}`}>{row.user}</Link>
              &nbsp;declined the challenge of&nbsp;
              <Link to={`/profile/${row?.target}`}>{row?.target}</Link>
            </>
          ) : row.type === "cancel" ? (
            <>
              <Link to={`/profile/${row.user}`}>{row.user}</Link>&nbsp;cancelled
              the challenge.
            </>
          ) : row.type === "quick-token" ? (
            <>
              The token was generated for quick fight between{" "}
              <Link to={`/profile/${row.user}`}>{row.user}</Link> and{" "}
              <Link to={`/profile/${row?.target}`}>{row?.target}</Link>
              {" ("}
              <Link to={`/result/${row?.token}/quick`} className="text-green-600">{row?.token}</Link>
              {")"}
            </>
          ) : row.type === "schedule-token" ? (
            <>
              The token was generated for schedule fight between{" "}
              <Link to={`/profile/${row.user}`}>{row.user}</Link> and{" "}
              <Link to={`/profile/${row?.target}`}>{row?.target}</Link>
              {" ("}
              <Link to={`/result/${row?.token}/quick`} className="text-green-600">{row?.token}</Link>
              {")"}
            </>
          ) : (
            <>
              <Link to={`/profile/${row.user}`}>{row.user}</Link>
              &nbsp;sent {row?.type} challenge to&nbsp;
              <Link to={`/profile/${row?.target}`}>{row?.target}</Link>
            </>
          )}
        </div>
      ),
      style: {
        fontSize: "16px",
      },
    },
  ];

  const rankingTableColumns = [
    {
      name: "Ranking",
      selector: (row) => row.ranking,
      sortable: true,
      center: "true",
      style: {
        fontSize: "16px",
      },
    },
    {
      name: t("username"),
      selector: (row) => row.avatar,
      cell: (row) => (
        <div
          className="flex items-center space-x-4 cursor-pointer"
          onClick={() => handleProfile(row.name)}
        >
          <div className="flex items-center justify-center">
            {row.avatar ? (
              <img
                className="w-8 h-8 rounded-full border"
                src={row.avatar}
                alt="avatar"
              />
            ) : (
              <div className="w-8 h-8 rounded-full border text-center flex items-center justify-center font-bold text-2xl">
                {row.name.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
          <div className="font-md">{row.name}</div>
        </div>
      ),
    },
    {
      name: "XP",
      selector: (row) => row.xp,
      sortable: true,
      center: "true",
      style: {
        fontSize: "16px",
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
      },
    },
  };

  return { eventColumns, rankingTableColumns, customStyles };
};
