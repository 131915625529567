const OnlineIndicator = ({ connected, occupied }) => {
  return (
    <div>
      {connected ? (
        occupied ? (
          <span className="absolute z-10 top-1 left-1 flex h-2 w-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-600 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-700"></span>
          </span>
        ) : (
          <span className="absolute z-10 top-1 left-1 flex h-2 w-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-700"></span>
          </span>
        )
      ) : (
        <span className="absolute z-10 top-1 left-1 flex h-2 w-2">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-600 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-2 w-2 bg-gray-700"></span>
        </span>
      )}
    </div>
  );
};

export default OnlineIndicator;
