export const updateUserScoreHistory = (score, legNo, setUserScoreHistory) => {
  setUserScoreHistory((prevHistory) => {
    const newHistory = [...prevHistory];
    if (!newHistory[legNo - 1]) {
      newHistory[legNo - 1] = [];
    }
    newHistory[legNo - 1] = [...newHistory[legNo - 1], score];
    return newHistory;
  });
};

export const checkBullFinish = (scoreHistory, opponentHistory) => {
  if (scoreHistory.length < 3 || opponentHistory.length < 3) return false;
  if (scoreHistory.length % 3 !== 0 || opponentHistory.length % 3 !== 0)
    return false;
  const userScore = scoreHistory.reduce((acc, cur) => acc + cur, 0);
  const opponentScore = opponentHistory.reduce((acc, cur) => acc + cur, 0);
  return userScore !== opponentScore;
};

export const checkTurn = (length, isChallenger) => {
  if (length === 0) {
    return isChallenger;
  }

  const isEvenRound = Math.floor((length - 1) / 3) % 2 === 0;
  const turnType = length % 3;

  if (isEvenRound) {
    return turnType === 2 ? isChallenger : !isChallenger;
  } else {
    return turnType === 2 ? !isChallenger : isChallenger;
  }
};
