import AvatarComponent from "../AvatarComponent";
import ToolTip from "../ToolTip/ToolTip";

const ArenaAvatarComponent = ({ users = [] }) => {
  const userCount = users.length;

  return (
    <div
      className={`${userCount > 12 ? 'gap-2' : 'gap-4'} flex flex-wrap items-center p-4 bg-white dark:bg-gray-900 rounded-md`}
    >
      {users.map((user, index) => (
        <ToolTip key={index} text={user?.username}>
          <AvatarComponent
            username={user?.username}
            avatar={user?.avatar}
            avatarSize={16}
          />
        </ToolTip>
      ))}
    </div>
  );
};

export default ArenaAvatarComponent;
