import React, { useMemo } from "react";
import {
  ResponsiveContainer,
  XAxis,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  CartesianGrid,
  YAxis,
} from "recharts";

const HomeFightsChartsLast = ({ currentData = [], lastData = [], isWeek }) => {
  const chartData = useMemo(() => {
    const combinedData = [];

    const addData = (data, period) => {
      data.forEach((item) => {
        const date = new Date(item._id);
        const name = isWeek
          ? date.toLocaleString("en-US", { weekday: "short" })
          : date.getDate();

        const existing = combinedData.find((d) => d.name === name);
        if (existing) {
          existing[period] = item.count;
        } else {
          combinedData.push({
            name,
            [period]: item.count,
          });
        }
      });
    };

    addData(currentData, isWeek ? "currentWeek" : "currentMonth");
    addData(lastData, isWeek ? "lastWeek" : "lastMonth");

    return combinedData?.sort((a, b) => a.name - b.name);
  }, [currentData, lastData, isWeek]);

  const legendFormatter = (value) => {
    if (isWeek) {
      return value === "currentWeek" ? "Current Week" : "Last Week";
    } else {
      const currentMonth = new Date().toLocaleString("en-US", { month: "long" });
      const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString("en-US", { month: "long" });
      return value === "currentMonth" ? currentMonth : lastMonth;
    }
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className="bg-white dark:bg-gray-900 rounded-md py-2"
    >
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend formatter={legendFormatter} />
        <Bar dataKey={isWeek ? "currentWeek" : "currentMonth"} fill="#8884d8" />
        <Bar dataKey={isWeek ? "lastWeek" : "lastMonth"} fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default React.memo(HomeFightsChartsLast);
