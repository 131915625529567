import { useMemo } from "react";
import { CalendarIcon } from "@heroicons/react/24/solid";

const ArenaReplyDate = ({ date }) => {
  const newDate = useMemo(() => new Date(date), [date]);

  const [time, period] = useMemo(() => {
    const formattedTime = newDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime.split(" ");
  }, [newDate]);

  return (
    <div className="flex items-center gap-4 text-white border border-white rounded-lg w-fit py-2 px-4">
      <CalendarIcon className="w-6 h-6 text-white" />
      <div className="flex flex-col items-start text-sm">
        <p>Match Date and Time</p>
        <p className="flex items-center gap-2">
          <p className="w-2 h-2 rounded-full bg-green-500"></p>
          {newDate.toDateString()}
        </p>
      </div>
      <div className="flex flex-col font-medium gap-1">
        <p>{time}</p>
        <p>{period}</p>
      </div>
    </div>
  );
};

export default ArenaReplyDate;
