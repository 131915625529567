import http from "../helper/http-client";

export const getMatchStatus = async (token) => {
  try {
    const { data } = await http.get(`/score/get/match-status/${token}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const updateBullScore = async (token, username, score) => {
  try {
    const { data } = await http.post("/score/update/bull-score", {
      score,
      token,
      username,
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const undoLastScore = async (
  token,
  user,
  score,
  missed,
  toFinish,
  bust
) => {
  try {
    const { data } = await http.post("/score/update/undo-last-score", {
      token,
      user,
      score,
      missed,
      toFinish,
      bust,
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
