import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../helper/http-client";

export const handleGetArenaByTitle = createAsyncThunk(
  "arena/getArenaByTitle",
  async (arenaTitle, thunkAPI) => {
    try {
      const res = await http.get(`/arena/get/${arenaTitle}`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleGetJoinedUsers = createAsyncThunk(
  "arena/getJoinedUsers",
  async (arenaTitle, thunkAPI) => {
    try {
      const res = await http.get(`/arena/${arenaTitle}/users`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleGetMatchResults = createAsyncThunk(
  "arena/getMatchResults",
  async ({ arenaTitle, page, limit = 9, username = "" }, thunkAPI) => {
    try {
      const res = await http.get(
        `/arena/get/results/${arenaTitle}?page=${page}&limit=${limit}&username=${username}`
      );
      console.log("res", res.data);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const initialState = {
  arena: null,
  joinedUsers: [],
  matchResults: [],
  totalPages: 1,
  isLoading: false,
  error: null,
};

export const arenaSlice = createSlice({
  name: "arena",
  initialState,
  reducers: {
    setArena: (state, action) => {
      state.arena = action.payload;
    },
    setJoinedUsers: (state, action) => {
      state.joinedUsers = action.payload;
    },
    setMatchResults: (state, action) => {
      state.matchResults = action.payload;
    },
    updateMatchResults: (state, action) => {
      let origin = state.matchResults;
      const arr = [...state.matchResults, { ...action.payload }];
      state.matchResults = arr
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, origin.length);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleGetArenaByTitle.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleGetArenaByTitle.fulfilled, (state, action) => {
        state.arena = action.payload;
        state.matchResults = action.payload?.matchResults || [];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleGetArenaByTitle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(handleGetJoinedUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleGetJoinedUsers.fulfilled, (state, action) => {
        state.joinedUsers = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleGetJoinedUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(handleGetMatchResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleGetMatchResults.fulfilled, (state, action) => {
        state.matchResults = action.payload.matchResults || [];
        state.totalPages = action.payload.totalPages;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleGetMatchResults.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setArena, setJoinedUsers, setMatchResults, updateMatchResults } =
  arenaSlice.actions;

export default arenaSlice.reducer;
