import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../helper/http-client";

export const handleLogin = createAsyncThunk(
  "auth/login",
  async (authData, thunkAPI) => {
    try {
      const res = await http.post("/auth/login", authData);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const fetchUserInfo = createAsyncThunk(
  "auth/getUserInfo",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const userId = state.auth.user._id;

      const res = await http.get(`/auth/get/${userId}`);
      return res.data;
    } catch (err) {
      console.log("fetch-user-info-->>", err);
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleRegister = createAsyncThunk(
  "auth/register",
  async (authData, thunkAPI) => {
    try {
      const res = await http.post("/auth/register", authData);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleAvatarUpload = createAsyncThunk(
  "auth/avatarUpload",
  async (file, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("avatar", file);
      const res = await http.post("/avatar/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleLogout = createAsyncThunk(
  "auth/logout",
  async (auth, thunkAPI) => {
    try {
      await http.post("/auth/logout", {
        username: auth.user.username,
      });
      return null;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleChangePassword = createAsyncThunk(
  "auth/changePassword",
  async (authData, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const userId = state.auth.user._id;
      const res = await http.post(`/auth/change-password/${userId}`, authData);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleUserSkillLvlUp = createAsyncThunk(
  "auth/userSkillLvlUp",
  async ({ skill, requiredXp, incVal }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const userId = state.auth.user._id;
      const res = await http.post(`/auth/update-skill-lvl`, {
        userId,
        skill,
        requiredXp,
        incVal,
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const handleUserUpdate = createAsyncThunk(
  "auth/userUpdate",
  async (userData, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const userId = state.auth.user._id;
      const res = await http.post(`/auth/update/${userId}`, userData);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const fetchAllusers = createAsyncThunk(
  "auth/fetchAllUsers",
  async (thunkAPI) => {
    try {
      const res = await http.get("/auth/get-users");
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const initialState = {
  user: null,
  allUsers: [],
  token: null,
  isLoading: false,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateStamina: (state, action) => {
      state.user = { ...state.user, stamina: action.payload };
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    addUser: (state, action) => {
      state.allUsers = [...state.allUsers, action.payload];
    },
    updateAllUsers: (state, action) => {
      state.allUsers = state.allUsers.map((user) => {
        if (user._id === action.payload._id) {
          return { ...user, status: action.payload.status };
        }
        return user;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleLogin.fulfilled, (state, action) => {
        state.user = action.payload?.user;
        state.token = action.payload?.token;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleLogin.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(handleRegister.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleRegister.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleRegister.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(handleAvatarUpload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleAvatarUpload.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleAvatarUpload.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(handleLogout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleLogout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleLogout.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(handleChangePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleChangePassword.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleChangePassword.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(handleUserUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleUserUpdate.fulfilled, (state, action) => {
        state.user = action.payload?.data?.updatedUser;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleUserUpdate.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllusers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllusers.fulfilled, (state, action) => {
        state.allUsers = action.payload?.users;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchAllusers.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(handleUserSkillLvlUp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handleUserSkillLvlUp.fulfilled, (state, action) => {
        state.user = action.payload?.updatedUser;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(handleUserSkillLvlUp.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.user = action.payload?.user;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const {
  setIsLoading,
  updateAllUsers,
  setAllUsers,
  addUser,
  setUser,
  updateStamina,
} = authSlice.actions;

export default authSlice.reducer;
