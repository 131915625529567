import { useCallback, useEffect } from "react";

const BulloutDartboard = ({ turn, isFinish, submitScore }) => {
  const handleClick = useCallback(
    (evt) => {
      if (!turn) return;
      const score = evt.target.getAttribute("data-score");
      if (score) {
        submitScore(parseInt(score, 10));
      }
    },
    [submitScore, turn]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (turn && !isFinish) {
        if (!isNaN(event.key) && event.key !== " ") {
          switch (Number(event.key)) {
            case 1:
              submitScore(50);
              break;
            case 3:
              submitScore(25);
              break;
            case 5:
              submitScore(0);
              break;
            default:
              break;
          }
        }
      }
    },
    [turn, isFinish, submitScore]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div
      className={`relative border border-yellow-200 bg-white rounded-lg flex items-center justify-center text-3xl text-white font-bold cursor-default select-none`}
      style={{ width: "300px", height: "300px" }}
      onClick={handleClick}
    >
      {turn && !isFinish ? null : (
        <div className="absolute w-full h-full top-0 bg-gray-500 opacity-50 z-30"></div>
      )}
      <div
        className={`absolute p-3 rounded-full bg-white border border-black text-black`}
        style={{ width: "90%", height: "90%" }}
        data-score="0"
      >
        5
      </div>
      <div
        className="absolute p-3 rounded-full bg-green-600"
        style={{ width: "55%", height: "55%" }}
        data-score="25"
      >
        3
      </div>
      <div
        className="absolute flex items-center justify-center rounded-full bg-red-600"
        style={{ width: "20%", height: "20%" }}
        data-score="50"
      >
        1
      </div>
    </div>
  );
};

export default BulloutDartboard;
