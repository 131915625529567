import { useTranslation } from "react-i18next";
import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";

const PriceCard = ({ msg, price = 2, buyCustomPrice }) => {
  const { t } = useTranslation();
  return (
    <div className="relative bg-green-100 dark:bg-gray-900 text-black dark:text-white border border-green-100 dark:border-gray-900 rounded-md p-8 max-w-lg w-full md:w-1/2 lg:w-1/4 min-h-96 mb-8">
      <h2 className="text-left font-bold text-3xl tracking-wide mb-8">
        €{price}
      </h2>
      <div className="flex justify-center">
        <PayPalButtons
          style={{
            layout: "vertical",
            color: "gold",
            shape: "rect",
            label: "paypal",
          }}
          fundingSource={FUNDING.PAYPAL}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: price.toString(), // Ensure the price is a string
                    currency_code: "EUR",
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              const name = details.payer.name.given_name;
              const amount =
                details.purchase_units[0].payments.captures[0].amount.value;

              const amountInt = Math.floor(parseFloat(amount));

              switch (amountInt) {
                case 2:
                  buyCustomPrice(6, 2);
                  break;
                case 5:
                  buyCustomPrice(20, 5);
                  break;
                case 10:
                  buyCustomPrice(50, 10);
                  break;
                default:
                  break;
              }

              toast.success(`${t("paymentSuccessful")}" "${name}.`);
            });
          }}
          onError={(err) => {
            console.error("An error occurred", err);

            toast.error(t("paymentError"));
          }}
        />
      </div>

      <p className="text-left mt-12">{msg}</p>
    </div>
  );
};

export default PriceCard;
