import { useEffect, useCallback } from "react";
import socket from "../../socket";

const useScoringSocket = ({
  user,
  legNo,
  setOpponentCurrentScore,
  setOpponentScoreHistory,
  setUserCurrentScore,
  setUserScoreHistory,
  setOpponentWin,
  setLegNo,
  resetScores,
  setUserWin,
  setUserTurn,
  setIsBullModalOpen,
  setIsFinished,
  setMatchStatus,
  fetchUserScore,
}) => {
  const handleScoreUpdate = useCallback(
    ({ updatedMatch }) => {
      setMatchStatus(updatedMatch);
      if (updatedMatch.legNo === legNo + 1) {
        setLegNo((prevLeg) => prevLeg + 1);
        resetScores();
        if (user.username === updatedMatch.p1.name) {
          setUserWin(updatedMatch.p1.legs_won);
          setOpponentWin(updatedMatch.p2.legs_won);
        } else {
          setUserWin(updatedMatch.p2.legs_won);
          setOpponentWin(updatedMatch.p1.legs_won);
        }
      }

      if (user.username === updatedMatch.p1.name) {
        setUserCurrentScore(updatedMatch.p1.currentScore);
        setOpponentCurrentScore(updatedMatch.p2.currentScore);
        setOpponentScoreHistory(
          updatedMatch.p2.scoreHistory.map((score) => score?.scores)
        );
        setUserScoreHistory(
          updatedMatch.p1.scoreHistory.map((score) => score?.scores)
        );
        setUserTurn(updatedMatch.challengerTurn);
      } else {
        setUserCurrentScore(updatedMatch.p2.currentScore);
        setOpponentCurrentScore(updatedMatch.p1.currentScore);
        setUserScoreHistory(
          updatedMatch.p2.scoreHistory.map((score) => score?.scores)
        );
        setOpponentScoreHistory(
          updatedMatch.p1.scoreHistory.map((score) => score?.scores)
        );
        setUserTurn(!updatedMatch.challengerTurn);
      }
    },
    [
      user,
      legNo,
      resetScores,
      setOpponentCurrentScore,
      setOpponentScoreHistory,
      setOpponentWin,
      setLegNo,
      setUserWin,
      setUserTurn,
      setMatchStatus,
      setUserCurrentScore,
      setUserScoreHistory,
    ]
  );

  const handleFinish = useCallback(() => {
    fetchUserScore();
    setIsFinished(true);
  }, [fetchUserScore, setIsFinished]);

  const handleBullScore = useCallback(
    (match) => {
      setMatchStatus(match);
      if (user.username === match.p1?.name) {
        setUserTurn(match.challengerTurn);
        setIsBullModalOpen(!match.p1.bull.isClosed);
      } else {
        setUserTurn(!match.challengerTurn);
        setIsBullModalOpen(!match.p2.bull.isClosed);
      }
    },
    [user.username, setMatchStatus, setUserTurn, setIsBullModalOpen]
  );

  useEffect(() => {
    socket.on("bull-score-response", handleBullScore);
    return () => {
      socket.off("bull-score-response", handleBullScore);
    };
  }, [handleBullScore]);

  useEffect(() => {
    socket.on("score-update-response", handleScoreUpdate);
    socket.on("finish-match", handleFinish);
    return () => {
      socket.off("score-update-response", handleScoreUpdate);
      socket.off("finish-match", handleFinish);
    };
  }, [handleScoreUpdate, handleFinish]);
};

export default useScoringSocket;
