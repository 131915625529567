import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import http from "../helper/http-client";

const useCommunityEvent = () => {
  const { user } = useSelector((state) => state.auth);
  const [fightsInDay, setFightsInDay] = useState([]);
  const [fightsInWeek, setFightsInWeek] = useState([]);
  const [fightsUserInDay, setFightsUserInDay] = useState([]);
  const [fightsUserInWeek, setFightsUserInWeek] = useState([]);
  const [participantsInDay, setParticipantsInDay] = useState([]);
  const [participantsInWeek, setParticipantsInWeek] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [winsChallenge, setWinsChallenge] = useState([]);

  const handleJacksVictoryReward = async (payload) => {
    try {
      await http.post("/result/jacks-victory", { username: payload._id });
      console.log("Reward for user: ", payload);
    } catch (error) {
      console.log(error);
    }
  };

  useMemo(async () => {
    setIsLoading(true);
    try {
      const response = await http.get("/event/fights-day");
      setFightsInDay(response.data.count);
      setParticipantsInDay(response.data.participants);
      setFightsUserInDay(response.data.fightsPerUser);

      const responseWeek = await http.get("/event/fights-week");
      setFightsInWeek(responseWeek.data.count);
      setParticipantsInWeek(responseWeek.data.participants);
      setFightsUserInWeek(responseWeek.data.fightsPerUser);

      const responseWins = await http.get("/event/wins-per-user");
      setWinsChallenge(responseWins.data?.winsPerUserLast48Hours);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    user,
    fightsInDay,
    fightsInWeek,
    fightsUserInDay,
    fightsUserInWeek,
    participantsInDay,
    participantsInWeek,
    handleJacksVictoryReward,
    winsChallenge,
    isLoading,
  };
};

export default useCommunityEvent;
