import { useState } from "react";

const useCheckDouble = (currentScore) => {
  const [possibleDoubleArr, setPossibleDoubleArr] = useState([]);
  const [possibleCheckArr, setPossibleCheckArr] = useState([]);

  const checkDouble = (score) => {
    if (currentScore - score === 0) {
      setPossibleDoubleArr([0, 1, 2]);
      setPossibleCheckArr([1, 2, 3]);

      if (currentScore > 120) {
        setPossibleCheckArr((prev) => prev.filter((num) => num !== 2));
      } else {
        setPossibleCheckArr((prev) =>
          [...new Set([...prev, 2])].sort((a, b) => b - a)
        );
      }

      if (
        (currentScore <= 40 && currentScore % 2 === 0) ||
        currentScore === 50
      ) {
        setPossibleDoubleArr((prev) =>
          [...new Set([...prev, 2])].sort((a, b) => b - a)
        );
        setPossibleCheckArr((prev) =>
          [...new Set([...prev, 1])].sort((a, b) => b - a)
        );
      } else {
        setPossibleDoubleArr([0, 1]);
        setPossibleCheckArr((prev) => prev.filter((num) => num !== 1));
      }

      if (currentScore > 110) {
        setPossibleDoubleArr([0]);
        setPossibleCheckArr([3]);
      } else {
        setPossibleDoubleArr((prev) =>
          [...new Set([...prev, 1])].sort((a, b) => b - a)
        );
      }
    } else {
      if ((currentScore - score > 50 && score > 0) || currentScore > 170) {
        setPossibleDoubleArr([0, 1, 2, 3]);
        setPossibleCheckArr([1, 2, 3]);
      } else {
        if (
          (currentScore <= 40 && currentScore % 2 === 0) ||
          currentScore === 50
        ) {
          setPossibleDoubleArr((prev) =>
            [...new Set([...prev, 3])].sort((a, b) => b - a)
          );
        } else {
          setPossibleDoubleArr((prev) => prev.filter((num) => num !== 3));
        }

        if (currentScore <= 110) {
          setPossibleDoubleArr((prev) =>
            [...new Set([...prev, 2])].sort((a, b) => b - a)
          );
        } else {
          setPossibleDoubleArr((prev) => prev.filter((num) => num !== 2));
        }

        setPossibleDoubleArr((prev) =>
          [...new Set([...prev, 1])].sort((a, b) => b - a)
        );
        setPossibleDoubleArr((prev) =>
          [...new Set([...prev, 0])].sort((a, b) => b - a)
        );
        setPossibleCheckArr([]);
      }
    }
  };

  return {
    checkDouble,
    possibleDoubleArr,
    possibleCheckArr,
  };
};

export default useCheckDouble;
