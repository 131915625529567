import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CustomModal from "../CustomModal";
import { useTranslation } from "react-i18next";
import ResultAchievements from "../Result/ResultAchievements";
import { useScoringFightResult } from "../../hooks/Scoring/useScoringFightResult";
import Confetti from "../Confetti";

const AchievementModal = ({ isOpen, token }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { winUser, loseUser } = useScoringFightResult(token);

  const [open, setOpen] = useState(isOpen);
  const handleClose = () => {
    setOpen(false);
  };

  const handleHooray = useCallback(() => {
    setOpen(false);
    navigate(`/scoring-result/${token}`);
  }, [navigate, token]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  return (
    <CustomModal title="Achievements" isOpen={open} onClose={handleClose}>
      <div className="pt-4">
        {winUser.name && loseUser.name ? (
          <ResultAchievements winUser={winUser} loseUser={loseUser} />
        ) : null}
        <div className="mt-4 flex justify-center gap-2">
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={handleHooray}
          >
            {t("hooray")}
          </button>
        </div>
      </div>
      <Confetti />
    </CustomModal>
  );
};

export default AchievementModal;
