import { useState, useMemo } from "react";
import http from "../helper/http-client";

const useMostFights = () => {
  const [mostFights, setMostFights] = useState([]);

  useMemo(async () => {
    try {
      const response = await http.get("/event/most-fights");
      setMostFights(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return mostFights;
};

export default useMostFights;
