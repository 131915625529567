import images from "../../helper/images";
import AvatarComponent from "../AvatarComponent";

const ArenaReplyAvatar = ({ playerWins, player, playerAvatar }) => {
  return (
    <div
      className="relative flex items-center gap-4 bg-cover bg-center p-3 font-bebas-neue tracking-widest rounded-md h-fit border border-green-500"
      style={{ backgroundImage: `url(${images.HOMESLIDER2})` }}
    >
      <div className="absolute w-full h-full top-0 left-0 dark:bg-[#171927bb] bg-[#0C0D1589] z-10 rounded-md"></div>
      <div className="flex flex-col gap-1 z-10">
        <p className="text-white font-bold text-xl uppercase">{player}</p>
        <p className="text-white font-bold font-quantico text-3xl uppercase">
          0 {playerWins}
        </p>
      </div>
      <div className="z-10">
        <AvatarComponent
          username={player}
          avatar={playerAvatar}
          avatarSize={20}
          isRoundedFull={false}
        />
      </div>
    </div>
  );
};

export default ArenaReplyAvatar;
