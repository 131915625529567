import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

const ScoringKeyPad = ({
  turn,
  isUndo,
  isFinished,
  handleNumberClick,
  handleEmpty,
  handleSubmit,
}) => {
  return (
    <div
      className={`bg-white dark:bg-gray-900 grid grid-cols-3 rounded-md ${
        (!turn || isFinished) && !isUndo ? "cursor-not-allowed opacity-50" : ""
      }`}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
        <button
          key={num}
          disabled={(!turn || isFinished) && !isUndo}
          className="p-2 sm:p-4 text-xl sm:text-3xl font-bold dark:text-white hover:bg-gray-200"
          onClick={() => handleNumberClick(num.toString())}
        >
          {num}
        </button>
      ))}
      <button
        disabled={(!turn || isFinished) && !isUndo}
        className="flex items-center justify-center bg-red-700 hover:bg-red-600 text-white rounded-bl-md"
        onClick={handleEmpty}
      >
        <XCircleIcon className="w-8 h-8" />
      </button>
      <button
        disabled={(!turn || isFinished) && !isUndo}
        className="p-2 sm:p-4 text-xl sm:text-3xl font-bold dark:text-white hover:bg-gray-200"
        onClick={() => handleNumberClick("0")}
      >
        0
      </button>
      <button
        disabled={(!turn || isFinished) && !isUndo}
        className="flex items-center justify-center bg-green-600 hover:bg-green-500 text-white rounded-br-md"
        onClick={handleSubmit}
      >
        <CheckCircleIcon className="w-8 h-8" />
      </button>
    </div>
  );
};

export default ScoringKeyPad;
