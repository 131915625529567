import { useMemo } from "react";
import { useSelector } from "react-redux";

const usePlayerAvatar = (username) => {
  const { allUsers } = useSelector((state) => state.auth);

  return useMemo(() => {
    const player = allUsers.find((user) => user.username === username);
    return player?.avatar;
  }, [allUsers, username]);
};

export default usePlayerAvatar;
