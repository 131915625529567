import useCommunityEvent from "../hooks/useCommunityEvent";
import SpinnerLoading from "../components/SpinnerLoading";
import CommunityProgressBar from "../components/Community/CommunityProgressBar";
import WinsChallenge from "../components/Community/WinsChallenge";

const CommunityEvents = () => {
  const {
    user,
    fightsInDay,
    fightsInWeek,
    participantsInDay,
    participantsInWeek,
    fightsUserInDay,
    fightsUserInWeek,
    winsChallenge,
    handleJacksVictoryReward,
    isLoading,
  } = useCommunityEvent();

  return (
    <div className="py-16 px-8">
      <h1 className="font-bold text-5xl dark:text-white">Community Events</h1>
      <div className="my-12">
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <div className="flex flex-col gap-4">
            <CommunityProgressBar
              title={"100 Fights in 24 Hours"}
              fights={fightsInDay}
              fightsPerUser={fightsUserInDay}
              total={100}
              participants={participantsInDay}
            />
            <CommunityProgressBar
              title={"500 Fights in a Week"}
              fights={fightsInWeek}
              fightsPerUser={fightsUserInWeek}
              total={500}
              participants={participantsInWeek}
            />
            <WinsChallenge
              data={winsChallenge}
              user={user}
              handleJacksVictoryReward={handleJacksVictoryReward}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityEvents;
