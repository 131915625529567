import { ArrowUturnLeftIcon, XMarkIcon } from "@heroicons/react/24/solid";
import ArenaReplyDate from "../Arena/ArenaReplyDate";

const ScoringPageHeader = ({
  legNo,
  isBullModalOpen,
  setIsFinishModal,
  setIsBackModal,
}) => {
  return (
    <div className="relative z-10">
      <div className="flex flex-row items-center sm:items-start px-8 z-10 mt-10 sm:mt-24 gap-2">
        <h3 className="text-white text-4xl sm:text-6xl font-bebas-neue tracking-widest font-semibold uppercase">
          Leg <span className="text-green-500">{legNo}</span>
        </h3>
        <button
          className="border border-white bg-green-500 hover:border-green-700 rounded-md py-1 sm:py-2 px-2 sm:px-4 ml-auto text-white font-semibold disabled:bg-gray-400"
          disabled={isBullModalOpen}
          onClick={() => setIsFinishModal(true)}
        >
          <XMarkIcon className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
        <button
          className="border border-white bg-green-500 hover:border-green-700 rounded-md py-1 sm:py-2 px-2 sm:px-4 mr-0 sm:mr-4 text-white font-semibold"
          onClick={() => setIsBackModal(true)}
        >
          <ArrowUturnLeftIcon className="w-4 h-4 sm:w-6 sm:h-6" />
        </button>
      </div>

      <div className="flex justify-center sm:justify-start mt-2 sm:mt-4 px-8 z-10">
        <ArenaReplyDate date={new Date()} />
      </div>
    </div>
  );
};

export default ScoringPageHeader;
