import { useEffect, useState, useCallback } from "react";
import socket from "../../socket";
import { checkTurn } from "../../helper/scoring/utils";

const useBullSocket = (
  isChallenger,
  currentInputScore,
  scoreHistory,
  fetchUserScore,
  handleSetTurn
) => {
  const [opponentHistory, setOpponentHistory] = useState([]);
  const [isFinish, setIsFinish] = useState(false);

  const handleBullUpdate = useCallback(
    async (data) => {
      if (opponentHistory.length === 0) {
        await fetchUserScore();
      }
      setOpponentHistory((prev) => [...prev, data.score]);
      if (
        data.score !== currentInputScore &&
        scoreHistory.length === opponentHistory.length + 1
      ) {
        setIsFinish(true);
      }
    },
    [currentInputScore, scoreHistory, opponentHistory, fetchUserScore]
  );

  useEffect(() => {
    const length = opponentHistory.length;
    const userTurn = checkTurn(length, isChallenger);
    handleSetTurn(userTurn);
  }, [opponentHistory, isChallenger, handleSetTurn]);

  useEffect(() => {
    socket.on("bull-update-response", handleBullUpdate);
    return () => {
      socket.off("bull-update-response", handleBullUpdate);
    };
  }, [handleBullUpdate]);
  return { opponentHistory, isFinish };
};

export default useBullSocket;
