import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import constant from "../../helper/constant";

const AchievementGrandmaster = ({ result, achievementIcons, handleActive }) => {
  const { t } = useTranslation();
  return (
    <motion.li
      className="w-full lg:w-48p font-serif rounded-lg p-6 text-white shadow-green-900 shadow-md bg-gradient-to-br from-green-500 to-gray-900"
      variants={constant.TopDownAnimationitem}
    >
      <h2 className="font-semibold mb-4 text-3xl">{t("grandMaster")}</h2>
      <div className="flex flex-wrap justify-center items-center  gap-x-4 mb-4">
        <p className="font-semibold">{t("achievement")}</p>
        <p className="opacity-70">{t("highestAverage")}</p>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex">
          <p className="flex-none w-16"></p>
          <p className="font-semibold flex-1">{t("leg")}</p>
          <p className="font-semibold flex-1">{t("match")}</p>
        </div>
        <div className="flex items-center gap-4">
          <p className="flex-none w-16">{t("lifetime")}</p>
          {result?.lifetime?.leg >= 50 ? (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={
                  achievementIcons[
                    `grand_${handleActive(result?.lifetime?.leg)}_leg`
                  ]
                }
                className="w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          ) : (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={achievementIcons[`grand_50_leg`]}
                className="opacity-50 w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          )}
          {result?.lifetime?.match >= 50 ? (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={
                  achievementIcons[
                    `grand_${handleActive(result?.lifetime?.match)}_match`
                  ]
                }
                className="w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          ) : (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={achievementIcons[`grand_50_match`]}
                className="opacity-50 w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          )}
        </div>
        <div className="flex items-center gap-4">
          <p className="flex-none w-16">{t("season")}</p>
          {result?.season?.leg >= 50 ? (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={
                  achievementIcons[
                    `grand_${handleActive(result?.season?.leg)}_leg`
                  ]
                }
                className="w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          ) : (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={achievementIcons[`grand_50_leg`]}
                className="opacity-50 w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          )}
          {result?.season?.match >= 50 ? (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={
                  achievementIcons[
                    `grand_${handleActive(result?.season?.match)}_match`
                  ]
                }
                className="w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          ) : (
            <div className="flex flex-1 justify-center items-center">
              <img
                src={achievementIcons[`grand_50_match`]}
                className="opacity-50 w-28 h-28 sm:w-40 sm:h-40 md:w-28 md:h-28 lg:w-40 lg:h-40 xl:w-48 xl:h-48"
                alt="achievement-icon"
                style={{ height: "auto" }}
              />
            </div>
          )}
        </div>
      </div>
    </motion.li>
  );
};

export default AchievementGrandmaster;
