import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { fetchResultAll } from "../app/resultSlice";

import useMostFights from "../hooks/useMostFights";
import useFightsMonth from "../hooks/useFightsMonth";

import HomeUsersTable from "../components/Home/HomeUsersTable";
import HomeTopMembers from "../components/Home/HomeTopMembers";
import HomeSlider from "../components/Home/HomeSlider";
import HomeCounter from "../components/Home/HomeCounter";
import UsersChart from "../components/Home/UsersChart";
import FightStatistics from "../components/Home/FightStatistics";
import RecentEvent from "../components/Home/RecentEvent";
import MostFightsCard from "../components/Home/MostFightsCard";
import images from "../helper/images";
import HomeTopSection from "../components/Home/HomeTopSection";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { BGCOUNTER1, BGCOUNTER2 } = images;

  const { results } = useSelector((state) => state.result);

  const { season: seasonMostFights, lifetime: lifetimeMostFights } =
    useMostFights();
  const fights = useFightsMonth();

  const { seasonCount, lifetimeCount } = useMemo(() => {
    let totalSeasonCount = 0;
    let totalLifetimeCount = 0;

    if (results && Array.isArray(results)) {
      results.forEach((result) => {
        totalSeasonCount +=
          (result.friendlyChallenger?.season || 0) +
          (result.readyForIt?.season || 0);
        totalLifetimeCount +=
          (result.friendlyChallenger?.lifetime || 0) +
          (result.readyForIt?.lifetime || 0);
      });
    }

    return { seasonCount: totalSeasonCount, lifetimeCount: totalLifetimeCount };
  }, [results]);

  const seasonCounter = useMemo(
    () => (seasonCount ? seasonCount.toString().split("") : []),
    [seasonCount]
  );
  const lifetimeCounter = useMemo(
    () => (lifetimeCount ? lifetimeCount.toString().split("") : []),
    [lifetimeCount]
  );

  useEffect(() => {
    dispatch(fetchResultAll());
  }, [dispatch]);

  return (
    <div className="flex flex-col pb-12">
      <div className="mt-12 px-8 relative flex flex-wrap 2xl:flex-nowrap gap-4">
        <div className="w-full lg:w-[48%] 2xl:w-[23%]">
          <HomeCounter
            title={t("seasonFights")}
            data={seasonCounter}
            BGCOUNTER={BGCOUNTER1}
          />
        </div>
        <div className="w-full lg:w-[48%] 2xl:w-[23%]">
          <HomeCounter
            title={t("lifetimeFights")}
            data={lifetimeCounter}
            BGCOUNTER={BGCOUNTER2}
          />
        </div>
        <div className="w-full lg:w-[60%] 2xl:w-[30%]">
          <MostFightsCard
            season={seasonMostFights}
            lifetime={lifetimeMostFights}
          />
        </div>
        <div className="w-full lg:w-[36%] 2xl:w-[20%]">
          <HomeTopSection />
        </div>
      </div>
      <div className="flex gap-4 p-8">
        <HomeSlider />
      </div>
      <div className="mb-8 px-8">
        <FightStatistics fights={fights} />
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap px-4">
        <div className="w-full lg:w-1/2 p-4">
          <HomeTopMembers />
          <RecentEvent />
        </div>
        <div className="w-full lg:w-1/2">
          <HomeUsersTable />
          <UsersChart />
        </div>
      </div>
    </div>
  );
};

export default Home;
