import React, { useMemo } from "react";
import { ResponsiveContainer, XAxis, Tooltip, AreaChart, Area } from "recharts";

const HomeFightsChart = ({ data = [] }) => {
  const chartData = useMemo(() => {
    return data?.map((item) => {
      return {
        name: item?._id,
        fights: item?.count,
      };
    });
  }, [data]);

  return (
    <ResponsiveContainer
      width="100%"
      height={300}
      className="bg-white dark:bg-gray-900 rounded-md py-2"
    >
      <AreaChart data={chartData}>
        <XAxis dataKey="name" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="fights"
          stroke="#8884d8"
          fill="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default React.memo(HomeFightsChart);
