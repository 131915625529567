import { Outlet } from "react-router";

const ShopPage = () => {
  return (
    <div className="px-8 py-16">
      <Outlet />
    </div>
  );
};

export default ShopPage;
