import { useState } from "react";
import CustomModal from "../CustomModal";

const ArenaFightMethodModal = ({ isShow, onClose, handleArenaFight }) => {
  const [arenaOption, setArenaOption] = useState("Manual");

  const handleOptionChange = (e) => {
    setArenaOption(e.target.value);
  };

  const onArenaFight = () => {
    handleArenaFight(arenaOption);
    onClose();
  };
  return (
    <CustomModal isOpen={isShow} onClose={onClose}>
      <div className="bg-white dark:bg-gray-800 text-black dark:text-white pt-8">
        <div className="mb-4">
          <label className="flex items-center text-md">
            <input
              type="radio"
              name="arenaOption"
              value="Manual"
              checked={arenaOption === "Manual"}
              onChange={handleOptionChange}
              className="mr-2"
            />
            Manual
          </label>
          {/* <p className="text-xs pl-6 mt-2 opacity-80">
            {t("payAllDescription")}
          </p> */}
        </div>
        <div className="mb-4">
          <label className="flex items-center text-md">
            <input
              type="radio"
              name="arenaOption"
              value="Auto"
              checked={arenaOption === "Auto"}
              onChange={handleOptionChange}
              className="mr-2"
            />
            Auto
          </label>
          {/* <p className="text-xs pl-6 mt-2 opacity-80">
            {t("50-50-description")}
          </p> */}
        </div>
        <div className="flex mt-4">
          <button
            className="bg-green-400 dark:bg-gray-900 text-white rounded-md py-2 px-4 hover:bg-green-500 dark:hover:bg-gray-700 ml-auto"
            onClick={onArenaFight}
          >
            Start
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ArenaFightMethodModal;
