import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const RankingExpandableComponent = ({ data }) => {
    const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        name: `${t("victoryStreak")}(${t("lifetime")})`,
        value: data?.streak.lifetime,
      },
      {
        name: `${t("victoryStreak")}(${t("season")})`,
        value: data?.streak.season,
      },
      {
        name: `${t("challengeConqueror")}(${t("lifetime")})`,
        value: data?.challengeConqueror.lifetime,
      },
      {
        name: `${t("challengeConqueror")}(${t("season")})`,
        value: data?.challengeConqueror.season,
      },
      {
        name: `${t("pyramidClimber")}(${t("lifetime")})`,
        value: data?.pyramidClimber.lifetime,
      },
      {
        name: `${t("pyramidClimber")}(${t("season")})`,
        value: data?.pyramidClimber.season,
      },
      {
        name: `${t("master180")}(${t("lifetime")})`,
        value: data?.master180.lifetime,
      },
      {
        name: `${t("master180")}(${t("season")})`,
        value: data?.master180.season,
      },
      {
        name: `${t("grandMaster")}(${t("lifetime")} - ${t("match")})`,
        value: data?.grandMaster?.lifetime?.match,
      },
      {
        name: `${t("grandMaster")}(${t("season")} - ${t("match")})`,
        value: data?.grandMaster?.season?.match,
      },
      {
        name: `${t("grandMaster")}(${t("lifetime")} - ${t("leg")})`,
        value: data?.grandMaster?.lifetime?.leg,
      },
      {
        name: `${t("grandMaster")}(${t("season")} - ${t("leg")})`,
        value: data?.grandMaster?.season?.leg,
      },
      {
        name: `${t("friendlyChallenger")}(${t("lifetime")})`,
        value: data?.friendly.lifetime,
      },
      {
        name: `${t("friendlyChallenger")}(${t("season")})`,
        value: data?.friendly.season,
      },
      {
        name: `${t("readyForIt")}(${t("lifetime")})`,
        value: data?.ready.lifetime,
      },
      {
        name: `${t("readyForIt")}(${t("season")})`,
        value: data?.ready.season,
      },
      {
        name: `${t("consistentScorer")}(${t("lifetime")})`,
        value: data?.consistentScorer.lifetime,
      },
      {
        name: `${t("consistentScorer")}(${t("season")})`,
        value: data?.consistentScorer.season,
      },
      {
        name: `${t("pyramidProtector")}(${t("lifetime")})`,
        value: data?.pyramidProtector.lifetime,
      },
      {
        name: `${t("pyramidProtector")}(${t("season")})`,
        value: data?.pyramidProtector.season,
      },
      {
        name: `${t("ironDart")}(${t("lifetime")})`,
        value: data?.ironDart?.lifetime,
      },
      {
        name: `${t("ironDart")}(${t("season")})`,
        value: data?.ironDart?.season,
      },
      {
        name: `${t("monthlyMaestro")}(${t("lifetime")})`,
        value: data?.monthlyMaestro?.lifetime,
      },
      {
        name: `${t("monthlyMaestro")}(${t("season")})`,
        value: data?.monthlyMaestro?.season,
      },
      {
        name: `${t("breakfast")}(${t("lifetime")})`,
        value: data?.breakfast?.lifetime,
      },
      {
        name: `${t("breakfast")}(${t("season")})`,
        value: data?.breakfast?.season,
      },
      {
        name: `${t("dartEnthusiast")}(${t("lifetime")})`,
        value: data?.dart?.lifetime,
      },
      {
        name: `${t("dartEnthusiast")}(${t("season")})`,
        value: data?.dart?.season,
      },
      {
        name: `${t("dartsThrown")}(${t("lifetime")})`,
        value: data?.throwCount?.lifetime,
      },
      {
        name: `${t("dartsThrown")}(${t("season")})`,
        value: data?.throwCount?.season,
      },
    ],
    [data, t]
  );

  return (
    <div className="text-black dark:text-white bg-white dark:bg-gray-800 p-4 grid grid-cols-8 xl:grid-cols-12 gap-6">
      {columns?.map((column, index) => (
        <div key={index} className="col-span-4 sm:col-span-2">
          <p className="font-semibold">{column.name}</p>
          <p>{column.value}</p>
        </div>
      ))}
    </div>
  );
};

export default RankingExpandableComponent;
