import { useSelector, useDispatch } from "react-redux";
import { useMemo } from "react";
import { toast } from "react-toastify";

import { handleUserSkillLvlUp } from "../app/authSlice";
import { useConstant } from "./useConstant";

const useAvatarShop = () => {
  const { vAvatars } = useConstant();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const displayAvatars = useMemo(
    () =>
      vAvatars.map((avatar) => {
        const isSelected = user?.vAvatar?.title === avatar.title;
        const isLocked = user?.vAvatar?.ranks < avatar.ranks;
        const skillset = isSelected
          ? avatar.skillset.map((skill) => ({
              ...skill,
              value:
                skill.name === "XP Boost"
                  ? skill.value
                  : user.vAvatar[skill.name.toLocaleLowerCase()],
              requiredXp:
                skill.name === "XP Boost"
                  ? 0
                  : skill.name === "Scoring"
                  ? Math.floor(
                      (user.vAvatar[skill.name.toLocaleLowerCase()] +
                        5 -
                        skill.initValue) *
                        15
                    )
                  : Math.floor(
                      (user.vAvatar[skill.name.toLocaleLowerCase()] +
                        0.1 -
                        skill.initValue) *
                        750
                    ),
            }))
          : avatar.skillset;
        console.log("displayAvatars", avatar);
        return { ...avatar, isSelected, isLocked, skillset };
      }),
    [vAvatars, user]
  );

  const handleLvlUp = (userXp, payload) => {
    let updatedSkill = payload;
    let requiredXp = 100;

    if (payload.name === "Scoring") {
      requiredXp = Math.floor(
        (updatedSkill.value + 5 - updatedSkill.initValue) * 15
      );

      if (requiredXp > userXp) {
        toast.warning("You don't have enough XP to level up this skill.");
        return;
      }

      dispatch(
        handleUserSkillLvlUp({
          skill: "scoring",
          requiredXp: requiredXp,
          incVal: 5,
        })
      );

      updatedSkill = {
        ...payload,
        value: payload.value + 5,
        requiredXp: requiredXp,
      };
    } else {
      requiredXp = Math.floor(
        (updatedSkill.value + 0.1 - updatedSkill.initValue) * 750
      );

      if (requiredXp > userXp) {
        toast.warning("You don't have enough XP to level up this skill.");
        return;
      }

      dispatch(
        handleUserSkillLvlUp({
          skill: payload.name.toLowerCase(),
          requiredXp: requiredXp,
          incVal: 0.1,
        })
      );

      updatedSkill = {
        ...payload,
        value: payload.value + 0.1,
        requiredXp: requiredXp,
      };
    }
    console.log("handleLvlUp", updatedSkill);
  };

  return { user, displayAvatars, handleLvlUp };
};

export default useAvatarShop;
