import { useMemo } from "react";
import BulloutDartboard from "./BulloutDartboard";
import ScoringAvatarContainer from "./ScoringAvatarContainer";
import useDetermineTurn from "../../hooks/Scoring/useDetermineTurn";
import useBullSocket from "../../hooks/Scoring/useBullSocket";
import useBullFinish from "../../hooks/Scoring/useBullFinish";

const BulloutCheck = ({
  token,
  user,
  opponentUser,
  challenger,
  userWin,
  opponentWin,
  userTurn,
  isKeyboardVisible,
  fetchUserScore,
}) => {
  const isChallenger = useMemo(
    () => (user?.username === challenger ? true : false),
    [user, challenger]
  );

  const { turn, currentInputScore, scoreHistory, submitScore, handleSetTurn } =
    useDetermineTurn(user, opponentUser, challenger, userTurn);

  const { opponentHistory, isFinish } = useBullSocket(
    isChallenger,
    currentInputScore,
    scoreHistory,
    fetchUserScore,
    handleSetTurn
  );

  const { userScore, opponentScore } = useBullFinish(
    isChallenger,
    isFinish,
    scoreHistory,
    opponentHistory,
    user,
    opponentUser,
    token,
    fetchUserScore
  );

  return (
    <div className="relative z-10 grid grid-cols-2 sm:grid-cols-4 gap-8 px-4 xl:px-8 py-2 sm:py-8">
      <div className="sm:order-1">
        <ScoringAvatarContainer
          user={user}
          userCurrentScore={501}
          userHistory={scoreHistory}
          wins={userWin}
          turn={userTurn}
        />
      </div>
      <div
        className={`fixed bottom-16 sm:bottom-0 left-1/2 -translate-x-1/2 sm:relative sm:flex sm:justify-center sm:items-start order-last col-span-2 sm:order-2 z-20 ${
          isKeyboardVisible ? "visible" : "hidden"
        } sm:block`}
      >
        {isFinish ? (
          userScore > opponentScore ? (
            <p className="relative z-10 bg-green-400 text-white text-4xl font-bold py-2 px-4 rounded-lg">
              Your turn first!
            </p>
          ) : (
            <p className="relative z-10 bg-green-400 text-white text-4xl font-bold py-2 px-4 rounded-lg">
              Opponent's turn first!
            </p>
          )
        ) : (
          <BulloutDartboard
            turn={turn}
            isFinish={isFinish}
            submitScore={submitScore}
          />
        )}
      </div>
      <div className="sm:order-3">
        <ScoringAvatarContainer
          user={opponentUser}
          userCurrentScore={501}
          userHistory={opponentHistory}
          wins={opponentWin}
          turn={!userTurn}
        />
      </div>
    </div>
  );
};

export default BulloutCheck;
