function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const isVariableEmpty = (value) => {
  if (value == null) {
    return true;
  }

  if (typeof value === "undefined") {
    return true;
  }

  if (!value) {
    return true;
  }

  if (value.length === 0) {
    return true;
  }

  if (typeof value === "object" && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const getRandomInt = (min, max) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
};

const convertAchievementName = (str) => {
  if (
    str.includes("master") ||
    str.includes("maestro") ||
    str.includes("protector") ||
    str.includes("conqueror") ||
    str.includes("climber") ||
    str.includes("grand")
  ) {
    return str
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return str.replace(/([A-Z])/g, " $1").toUpperCase();
  }
};

const getCurrentTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
};

const getRandomColor = (str) => {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += value.toString(16).padStart(2, '0')
  }
  return colour
};

const transformTableData = (data) => {
  return data
    .map((val, index) => ({
      id: val._id,
      ranking: val.level,
      avatar: val.avatar,
      name: val.username,
      breakfast: val.master26,
      streak: {
        lifetime: val.maxVictoryStreak,
        season: val.seasonMaxVictoryStreak,
      },
      friendly: val.friendlyChallenger,
      ready: val.readyForIt,
      dart: val.dartEnthusiast,
      consistentScorer: val.consistentScorer,
      ironDart: val.ironDart,
      monthlyMaestro: val.monthlyMaestro,
      pyramidProtector: val.pyramidProtector,
      grandMaster: val.grandMaster,
      challengeConqueror: val.challengeConqueror,
      pyramidClimber: val.pyramidClimber,
      master180: val.master180,
      throwCount: val.throwCount,
      xp: val.dXp
    }))
    .sort((a, b) => b.ranking - a.ranking);
};

const convertStr = (str) => {
  return str.replace(/([A-Z])/g, " $1").toUpperCase();
};

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th'; // covers 11th to 19th
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

function formatDate(date) {
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  const [month, day, year] = date.toLocaleDateString('en-US', options).replace(',', '').split(' ');
  const dayWithSuffix = `${parseInt(day)}${getOrdinalSuffix(parseInt(day))}`;
  return `${month}. ${dayWithSuffix} ${year}`;
}

function formatStringDate(dateString) {
  if (!dateString) return '';
  const dateParts = dateString.split('-');
  const date = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]));
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}. ${day}${getOrdinalSuffix(day)} ${year}`;
}

export {
  classNames,
  debounce,
  isVariableEmpty,
  getRandomInt,
  convertAchievementName,
  getCurrentTimeZone,
  transformTableData,
  convertStr,
  getRandomColor,
  formatDate,
  formatStringDate
};
