import HorizontalProgressBar from "../HorizontalProgressBar";
import AvatarComponent from "../AvatarComponent";

const CommunityProgressBar = ({
  title,
  fights,
  fightsPerUser = [],
  total,
  participants,
}) => {
  const getFightsPerUser = (participant) => {
    return (
      fightsPerUser.find((fights) => fights._id === participant?.username)
        ?.count || 0
    );
  };

  return (
    <div className="bg-white dark:bg-gray-900 dark:text-white font-poppins rounded-md p-4">
      <h2 className="text-left font-bold text-2xl mb-4">{title}</h2>
      <div className="flex items-center gap-4 mb-4">
        <HorizontalProgressBar progress={fights / total} />
        <p>
          {fights}&nbsp;/&nbsp;{total}
        </p>
      </div>
      <div className="flex flex-wrap flex-col sm:flex-row gap-2 max-h-80 overflow-hidden overflow-y-auto">
        {participants.map((participant) => (
          <div className="flex w-[48%] items-center gap-4">
            <AvatarComponent
              key={participant?._id}
              username={participant?.username}
              avatar={participant?.avatar}
              avatarSize={8}
              textSize="text-xl"
            />
            <p>{participant?.username}</p>
            <p>{getFightsPerUser(participant)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunityProgressBar;
