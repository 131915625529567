const InputComponent = ({
  name,
  icon,
  label,
  value,
  type,
  placeholder,
  disabled,
  required,
  onChange,
}) => {
  return (
    <div className="flex flex-col items-start gap-2">
      {label}
      <div className="relative">
        <span className="absolute text-gray-500 inset-y-0 left-0 flex items-center pl-2">
          {icon}
        </span>
        <input
          id={name}
          name={name}
          value={value}
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChange}
          className="block w-full rounded-md bg-white dark:bg-gray-800 text-black dark:text-white border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 focus-border-none focus-outline-none"
        />
      </div>
    </div>
  );
};

export default InputComponent;
