import "./App.css";

import AppRouter from "./routes";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'swiper/css';
import 'swiper/css/pagination';

import { useSocketConnection } from "./hooks/useSoecketConnection";

function App() {
  const initialOptions = {
    // "client-id":
    //   "ARg3a7PLpl-ysDBf_WA0sMuPZ5uXUxg7dDhCPQTdc-Pyi1vLCBB0wBletgeXP0bJZzN4HDqKFhJ0G8Z6",
    "client-id":
      "AcuXFNE8Ys6b5DrdbjIXr1mRtVv1olRqdr3cra2D1NpPASzIoFcqyzB1qioX1_MTovX8GGWDh9t9GPFo",
    currency: "EUR",
    intent: "capture",
  };
  useSocketConnection();

  return (
    <div className="App bg-white dark:bg-gray-800 overflow-auto">
      <PayPalScriptProvider options={initialOptions}>
        <AppRouter />
        <ToastContainer />
      </PayPalScriptProvider>
    </div>
  );
}

export default App;
