import React, { useState, useMemo } from "react";
import HomeFightsChart from "./HomeFightsChart";
import CustomDropdown from "../CustomDropdown";
import HomeFightsChartsLast from "./HomeFightsChartLast";

const FightStatistics = ({ fights }) => {
  const [selectedOption, setSelectedOption] = useState("month");
  const [isLastMonth, setIsLastMonth] = useState(true);

  const options = [
    { value: "month", label: "Month" },
    { value: "week", label: "Week" },
  ];

  const fightsData = useMemo(() => {
    if (isLastMonth) {
      if (selectedOption === "week") {
        return { last: fights?.lastWeek, current: fights?.currentWeek };
      }

      return { last: fights?.lastMonth, current: fights?.currentMonth };
    } else {
      if (selectedOption === "week") {
        return fights?.currentWeek;
      }

      return fights?.currentMonth;
    }
  }, [fights, selectedOption, isLastMonth]);

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 bg-white dark:bg-gray-900 dark:text-white mb-2 py-4 px-6 rounded-md mb-4">
        <p className="font-bold text-xl font-poppins">
          Fights Statistics
          <span className="font-normal text-lg">
            ({selectedOption === "week" ? "Week" : "Month"})
          </span>
        </p>
        <div className="flex itesm-center gap-4 sm:ml-auto">
          <CustomDropdown
            options={options}
            initialValue="month"
            onChange={handleSelect}
          />
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="lastMonth"
              name="lastMonth"
              className="h-5 w-5"
              value={isLastMonth}
              checked={isLastMonth}
              onChange={() => setIsLastMonth(!isLastMonth)}
            />
            <label htmlFor="lastMonth" className="mr-2">
              Last {selectedOption === "week" ? "Week" : "Month"}
            </label>
          </div>
        </div>
      </div>
      {isLastMonth ? (
        <HomeFightsChartsLast
          currentData={fightsData?.current}
          lastData={fightsData?.last}
          isWeek={selectedOption === "week"}
        />
      ) : (
        <HomeFightsChart data={fightsData} />
      )}
    </div>
  );
};

export default React.memo(FightStatistics);
