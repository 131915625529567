import { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import http from "../../helper/http-client";
import socket from "../../socket";
import constant from "../../helper/constant";
import { determineTurn } from "../../helper/scoring";
import {
  handleScoreUpdateSocketEvent,
  handleFinishSocketEvent,
} from "../../socket/scoringSocketEvent";
import { updateUserScoreHistory } from "../../helper/scoring/utils";
import useFetchScoring from "./useFetchScoring";
import useScoringSocket from "./useScoringScoket";
import { undoLastScore } from "../../api/scoring.api";

const useScoringSystem = (opponent, challenger, token) => {
  const navigate = useNavigate();
  const { allUsers, user } = useSelector((state) => state.auth);
  const [doubleMissed, setDoubleMissed] = useState(0);
  const [inputScore, setInputScore] = useState(0);
  const [modalType, setModalType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFinishModal, setIsFinishModal] = useState(false);
  const [isBackModal, setIsBackModal] = useState(false);
  const [isUndo, setIsUndo] = useState(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const {
    matchStatus,
    userCurrentScore,
    opponentCurrentScore,
    userScoreHistory,
    opponentScoreHistory,
    userWin,
    opponentWin,
    legNo,
    userTurn,
    isBullModalOpen,
    isFinished,
    isLoading,
    setMatchStatus,
    setLegNo,
    setUserCurrentScore,
    setOpponentCurrentScore,
    setUserScoreHistory,
    setOpponentScoreHistory,
    setUserWin,
    setOpponentWin,
    setUserTurn,
    setIsBullModalOpen,
    setIsFinished,
    fetchUserScore,
    resetScores,
    setIsLoading,
  } = useFetchScoring(token, user);

  useScoringSocket({
    user,
    legNo,
    setOpponentCurrentScore,
    setOpponentScoreHistory,
    setUserCurrentScore,
    setUserScoreHistory,
    setOpponentWin,
    setLegNo,
    resetScores,
    setUserWin,
    setUserTurn,
    setIsBullModalOpen,
    setIsFinished,
    setMatchStatus,
    fetchUserScore,
  });

  const { impossibleNumbers } = constant;

  // check if the keyboard event is enabled
  const isEnableKeyEvent = useMemo(() => {
    if (
      !isFinished &&
      !isModalOpen &&
      !isFinishModal &&
      !isBackModal &&
      !isBullModalOpen &&
      !isLoading &&
      userTurn
    )
      return true;
    else return false;
  }, [
    isFinished,
    isFinishModal,
    isModalOpen,
    isBackModal,
    isBullModalOpen,
    userTurn,
    isLoading,
  ]);

  // determin opponent user
  const opponentUser = useMemo(
    () => allUsers.find((user) => user?.username?.toLowerCase() === opponent),
    [allUsers, opponent]
  );

  // -----------------------------------Handlers----------------------------------------
  // toggle keyboard visible on mobile screen
  const onChangeKeyboardVisible = useCallback(() => {
    setIsKeyboardVisible((prev) => !prev);
  }, []);

  const handleSubmit = useCallback(
    (score) => {
      if (!(score >= 0 && score <= 180) || impossibleNumbers.includes(score)) {
        toast.error(
          "Score should be between 0 and 180 and not an impossible number. Please try again."
        );
        return;
      }
      setIsKeyboardVisible(false); // Hide the keyboard after submitting the score in mobile screen
      setInputScore(score);
      if (userTurn) {
        // player checkout
        if (userCurrentScore - score === 0) {
          setIsModalOpen(true);
          setModalType("doubleMissed");
          return;
        } else if (
          userCurrentScore - score < 0 ||
          userCurrentScore - score === 1 ||
          score === 0
        ) {
          setIsModalOpen(true);
          setInputScore(0);
          setModalType("bustThrown");
          return;
        } else {
          if (
            (userCurrentScore - score > 50 && score > 0) ||
            userCurrentScore > 170
          ) {
            handleScoreUpdateSocketEvent(
              score,
              0,
              3,
              0,
              token,
              isUndo,
              user,
              opponentUser
            );
            // setUserCurrentScore((prevScore) => prevScore - score);
            // updateUserScoreHistory(score, legNo, setUserScoreHistory);
            setUserTurn(false);
            setIsUndo(false);
          } else {
            setIsModalOpen(true);
            setModalType("doubleMissed");
          }
          return;
        }
      }
    },
    [
      impossibleNumbers,
      userCurrentScore,
      userTurn,
      user,
      isUndo,
      opponentUser,
      token,
      setUserTurn,
    ]
  );

  const handleUndoSubmit = useCallback(
    async (lastIdx, score, lastScore, thrown) => {
      setIsLoading(true);
      try {
        const data = await undoLastScore(
          token,
          user.username,
          score,
          0,
          0,
          thrown
        );
        setMatchStatus(data);
        setUserCurrentScore((prevScore) => prevScore + lastScore - score);
        setUserScoreHistory((prevHistory) => {
          const newHistory = [...prevHistory];
          newHistory[legNo - 1][lastIdx] = score;
          return newHistory;
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
        setIsUndo(false);
      }
    },
    [
      legNo,
      token,
      user,
      setUserCurrentScore,
      setUserScoreHistory,
      setIsLoading,
      setMatchStatus,
    ]
  );

  const handleUndo = useCallback(
    async (score) => {
      if (!(score >= 0 && score <= 180) || impossibleNumbers.includes(score)) {
        toast.error(
          "Score should be between 0 and 180 and not an impossible number. Please try again."
        );
        return;
      }
      setInputScore(score);
      const lastIdx = userScoreHistory[legNo - 1].length - 1;
      const lastScore = userScoreHistory[legNo - 1][lastIdx];

      // player checkout
      if (userCurrentScore + lastScore - score === 0) {
        setIsModalOpen(true);
        setModalType("doubleMissed");
        return;
      } else if (
        userCurrentScore + lastScore - score < 0 ||
        userCurrentScore + lastScore - score === 1 ||
        score === 0
      ) {
        setIsModalOpen(true);
        setInputScore(0);
        setModalType("bustThrown");
        return;
      } else {
        if (
          (userCurrentScore + lastScore - score > 50 && score > 0) ||
          userCurrentScore + lastScore > 170
        ) {
          handleUndoSubmit(lastIdx, score, lastScore, 3);
        } else {
          setIsModalOpen(true);
          setModalType("doubleMissed");
        }
        return;
      }
    },
    [
      impossibleNumbers,
      userScoreHistory,
      legNo,
      userCurrentScore,
      handleUndoSubmit,
    ]
  );

  const handleSubmitDouble = useCallback(
    async (double) => {
      setDoubleMissed(double);
      setIsModalOpen(false);
      if (isUndo) {
        const lastIdx = userScoreHistory[legNo - 1].length - 1;
        const lastScore = userScoreHistory[legNo - 1][lastIdx];

        if (userCurrentScore + lastScore - inputScore === 0) {
          setIsModalOpen(true);
          setModalType("checkoutThrown");
        } else {
          handleUndoSubmit(lastIdx, inputScore, lastScore, 3);
        }
      } else {
        if (userCurrentScore - inputScore === 0) {
          setIsModalOpen(true);
          setModalType("checkoutThrown");
        } else {
          handleScoreUpdateSocketEvent(
            inputScore,
            double,
            3,
            0,
            token,
            isUndo,
            user,
            opponentUser
          );
          setUserCurrentScore((prevScore) => prevScore - inputScore);
          updateUserScoreHistory(inputScore, legNo, setUserScoreHistory);
          setUserTurn(false);
        }
      }
    },
    [
      inputScore,
      isUndo,
      legNo,
      userCurrentScore,
      userScoreHistory,
      opponentUser,
      token,
      user,
      setUserCurrentScore,
      setUserScoreHistory,
      setUserTurn,
      handleUndoSubmit,
    ]
  );

  const handleSubmitCheckout = useCallback(
    (checkout) => {
      if (
        legNo + 1 > 5 ||
        (legNo > 2 && Math.abs(userWin + 1 - opponentWin) >= 2)
      ) {
        handleFinishSocketEvent(token, opponentUser);
        setIsFinished(true);
      }
      resetScores();
      setUserTurn(determineTurn(matchStatus, user, legNo));
      setUserWin((prevWin) => prevWin + 1);
      setLegNo((prevLeg) => prevLeg + 1);
      handleScoreUpdateSocketEvent(
        inputScore,
        doubleMissed,
        3,
        checkout,
        token,
        isUndo,
        user,
        opponentUser
      );
      setIsModalOpen(false);
      setIsUndo(false);
    },
    [
      inputScore,
      legNo,
      opponentUser,
      token,
      user,
      doubleMissed,
      matchStatus,
      userWin,
      isUndo,
      opponentWin,
      resetScores,
      setUserTurn,
      setUserWin,
      setLegNo,
      setIsFinished,
    ]
  );

  const handleSumitBust = useCallback(
    (bust) => {
      if (isUndo) {
        const lastIdx = userScoreHistory[legNo - 1].length - 1;
        const lastScore = userScoreHistory[legNo - 1][lastIdx];
        handleUndoSubmit(lastIdx, 0, lastScore, Number(bust));
      } else {
        setUserTurn(false);
        updateUserScoreHistory(0, legNo, setUserScoreHistory);
        handleScoreUpdateSocketEvent(
          0,
          0,
          Number(bust),
          0,
          token,
          isUndo,
          user,
          opponentUser
        );
        setIsUndo(false);
      }
      setIsModalOpen(false);
    },
    [
      legNo,
      opponentUser,
      userScoreHistory,
      token,
      user,
      isUndo,
      setUserScoreHistory,
      setUserTurn,
      handleUndoSubmit,
    ]
  );

  const handleSubmitModal = useCallback(
    (score) => {
      switch (modalType) {
        case "doubleMissed":
          handleSubmitDouble(score);
          break;
        case "checkoutThrown":
          handleSubmitCheckout(score);
          break;
        case "bustThrown":
          handleSumitBust(score);
          break;
        default:
          setIsModalOpen(false);
          break;
      }
    },
    [handleSubmitDouble, handleSubmitCheckout, handleSumitBust, modalType]
  );

  const handleGameFinish = () => {
    navigate(`/scoring-result/${token}`);
  };

  const handleFinishClick = async () => {
    try {
      setIsFinished(true);
      setIsFinishModal(false);
      await http.post("/result/finish-match", { token });
      socket.emit("match-finish", { token, opponentId: opponentUser._id });
      await fetchUserScore();
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const onClickUndo = useCallback(() => {
    setIsUndo((prev) => !prev);
  }, []);

  return {
    opponentUser,
    user,
    userCurrentScore,
    opponentCurrentScore,
    userScoreHistory,
    opponentScoreHistory,
    userWin,
    opponentWin,
    legNo,
    userTurn,
    modalType,
    isModalOpen,
    isFinished,
    isBackModal,
    isFinishModal,
    isBullModalOpen,
    isKeyboardVisible,
    isEnableKeyEvent,
    isUndo,
    matchStatus,
    isLoading,
    fetchUserScore,
    handleSubmitDouble,
    handleSubmit,
    handleGameFinish,
    handleFinishClick,
    handleBack,
    setIsBackModal,
    setIsFinishModal,
    setIsBullModalOpen,
    setIsModalOpen,
    handleSubmitModal,
    handleUndo,
    onChangeKeyboardVisible,
    onClickUndo,
    setIsLoading,
  };
};

export default useScoringSystem;
