import { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";

const UsersChart = () => {
  const { results } = useSelector((state) => state.result);

  const sortedUsers = useMemo(() => {
    let users = [];
    if (results) {
      for (let i = 0; i < 7; i++) {
        let levelUsers = results.filter((user) => user?.level === i);
        users.push(levelUsers);
      }
    }
    return users;
  }, [results]);

  const filteredUsers = useMemo(() => {
    return sortedUsers.filter((item) => item.length > 0);
  }, [sortedUsers]);

  const chartData = useMemo(() => {
    return filteredUsers.map((item, index) => {
      return {
        name: `Level ${index + 1}`,
        users: item?.length,
      };
    });
  }, [filteredUsers]);

  return (
    <div className="px-4">
      <div className="flex items-center justify-between bg-white dark:bg-gray-900 dark:text-white mb-2 py-4 px-6 rounded-md mb-4">
        <p className="font-bold text-xl font-poppins">Fight Club Users</p>
      </div>
      <ResponsiveContainer
        width="100%"
        height={300}
        className="bg-white dark:bg-gray-900 rounded-md py-2"
      >
        <BarChart
          data={chartData}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="users" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default UsersChart;
