import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import Loading from "../components/Loading";
import paginationComponentOptions from "../helper/constant";
import RankingExpandableComponent from "../components/RankingExpandableComponent";
import CustomMultiSelect from "../components/CustomMultiSelect";
import { useTableColumns } from "../hooks/useTableColumns";
import { useResult } from "../hooks/useResult";
import { useRankingFilter } from "../hooks/useRankingFilter";

const Ranking = () => {
  const { t } = useTranslation();
  const { rankingTableColumns, customStyles } = useTableColumns();
  const { transformedResults: result, isLoading } = useResult();
  const {
    isOpenFilterMenu,
    selectedFilterItems,
    checkedItems,
    rankingSortList,
    handleFilterMenu,
    closeDropdown,
    handleFilterCheck,
  } = useRankingFilter();

  let sortArray = selectedFilterItems.map((item) => {
    return {
      name: item.label,
      selector: (row) =>
        item.value === "grandMaster"
          ? row[item.value][item.type]?.match
          : row[item.value][item.type],
      sortable: true,
      style: {
        fontSize: "16px",
      },
    };
  });

  let displayColums = [...rankingTableColumns, ...sortArray];

  return (
    <div className="px-8 py-16 data-table">
      <div className="md:w-2/5 lg:w-1/3 bg-white dark:bg-gray-800 ml-auto mb-8">
        <CustomMultiSelect
          isOpen={isOpenFilterMenu}
          selectedValue={selectedFilterItems}
          handleMenu={handleFilterMenu}
          closeDropdown={closeDropdown}
        >
          <ul className="max-h-44 overflow-y-auto custom-scrollbar">
            {rankingSortList.map((item, index) => (
              <li
                key={index}
                className="flex items-center text-black dark:text-white bg-white dark:bg-gray-800 gap-2"
              >
                <input
                  type="checkbox"
                  value={item.name}
                  checked={checkedItems[item.name] || false}
                  onChange={handleFilterCheck}
                />
                {item.label}
              </li>
            ))}
          </ul>
        </CustomMultiSelect>
      </div>
      <DataTable
        title={
          <div className="text-4xl font-bold mb-4 bg-white dark:bg-gray-800 text-black dark:text-white">
            {t("ranking")}
          </div>
        }
        columns={displayColums}
        data={result}
        expandableRows
        expandableRowsComponent={RankingExpandableComponent}
        progressPending={isLoading}
        customStyles={customStyles}
        progressComponent={
          <div className="flex flex-col w-full gap-4">
            <Loading />
            <Loading />
            <Loading />
          </div>
        }
        pagination
        highlightOnHover
        paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
};

export default Ranking;
