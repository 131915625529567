const ArenaReplyProgress = ({ display, isMarkLeft, totalDisplay }) => {
  const getGradient = (value) => {
    if (501 - value > 301) {
      return "bg-gradient-to-b from-red-600 from-20% via-red-700 via-60% to-red-800 to-80%";
    } else if (501 - value > 170) {
      return "bg-gradient-to-b from-red-600 from-60% to-orange-400 to-80%";
    } else {
      return "bg-gradient-to-b from-red-600 from-40% via-orange-400 via-65% to-green-400 to-95%";
    }
  };

  return (
    <div className="relative w-3 h-full bg-gray-300 dark:bg-gray-800">
      <div
        className={`absolute top-0 left-0 w-full ${getGradient(display)}`}
        style={{ height: `${(display / totalDisplay) * 100}%` }}
      ></div>
      <div
        className={`absolute w-8 h-full ${
          isMarkLeft ? "left-2" : "right-1"
        } ml-2 text-xs text-gray-100 dark:text-gray-300`}
      >
        <div style={{ position: "absolute", top: "0%" }}>501</div>
        <div style={{ position: "absolute", top: "40%" }}>301</div>
        <div style={{ position: "absolute", top: "66%" }}>170</div>
        <div style={{ position: "absolute", top: "95%" }}>0</div>
      </div>
    </div>
  );
};

export default ArenaReplyProgress;
