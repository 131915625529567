import DataTable from "react-data-table-component";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import { useEvents } from "../hooks/useEvents";
import { usePagination } from "../hooks/usePagination";
import { useSorting } from "../hooks/useSorting";
import { useFiltering } from "../hooks/useFiltering";
import { useTableColumns } from "../hooks/useTableColumns";
import CustomInputComponent from "../components/CustomInput";
import CustomMultiSelect from "../components/CustomMultiSelect";
import Loading from "../components/Loading";

const Events = () => {
  const { t } = useTranslation();
  const { customStyles, eventColumns } = useTableColumns();
  const {
    isOpenFilterMenu,
    selectedFilterItems,
    checkedItems,
    eventFilterList,
    handleFilter,
    handleFilterMenu,
    closeDropdown,
    handleFilterCheck,
    handleFilterInput,
  } = useFiltering({}, { user: "" });
  const { page, rowsPerPage, handlePage, handleRowsPerPage } = usePagination(
    1,
    10
  );
  const { sortObj, handleSort } = useSorting({
    sortField: "date",
    sortDirection: "desc",
  });
  const { filterEvents, totalItems, isLoading } = useEvents(
    page,
    rowsPerPage,
    sortObj,
    selectedFilterItems
  );

  return (
    <div className="py-16 px-8">
      <div className="md:w-2/5 lg:w-1/3 bg-white dark:bg-gray-800 ml-auto mb-8">
        <CustomMultiSelect
          isOpen={isOpenFilterMenu}
          selectedValue={selectedFilterItems}
          handleMenu={handleFilterMenu}
          closeDropdown={closeDropdown}
        >
          <ul>
            {eventFilterList.map((item, index) => (
              <li
                key={index}
                className="flex items-center text-black dark:text-white bg-white dark:bg-gray-800 gap-2"
              >
                <input
                  type="checkbox"
                  value={item.name}
                  checked={checkedItems[item.name] || false}
                  onChange={handleFilterCheck}
                />
                {item.label}
              </li>
            ))}
            <li>
              <CustomInputComponent
                name="user"
                type="text"
                placeholder="Input user name"
                disabled={!checkedItems?.user}
                icon={
                  <MagnifyingGlassIcon
                    className="h-5 w-5 cursor-pointer"
                    onClick={handleFilter}
                  />
                }
                onChange={handleFilterInput}
              />
            </li>
          </ul>
        </CustomMultiSelect>
      </div>
      <div className="data-table">
        <DataTable
          title={
            <div className="bg-white dark:bg-gray-800 text-black dark:text-white text-4xl font-bold mb-4">
              {t("events")}
            </div>
          }
          columns={eventColumns}
          data={filterEvents}
          progressPending={isLoading}
          customStyles={customStyles}
          defaultSortFieldId="date"
          defaultSortAsc={false}
          progressComponent={
            <div className="w-full flex flex-col gap-4 bg-white dark:bg-gray-800">
              <Loading />
              <Loading />
              <Loading />
              <Loading />
              <Loading />
            </div>
          }
          pagination
          paginationServer
          paginationTotalRows={totalItems}
          onChangePage={handlePage}
          onChangeRowsPerPage={handleRowsPerPage}
          sortServer
          onSort={(columns, sortOrder) => handleSort(columns, sortOrder)}
        />
      </div>
    </div>
  );
};

export default Events;
