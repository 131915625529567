import { useState, useCallback, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useSeason from "../../hooks/useSeason";
import EmailNotify from "../../helper/emailNotify";
import images from "../../helper/images";
import OnlineIndicator from "../OnlineIndicator";
import PriceModal from "../PriceModal";
import WaitingModal from "../WaitingModal";
import { useResult } from "../../hooks/useResult";

const HomeTopSection = () => {
  const { t } = useTranslation();
  const { season } = useSeason();
  const { user, allUsers } = useSelector((state) => state.auth);
  const { lastSeason } = season;

  const { isChallengeLoading, sendQuickFight, handleWaitingCancel } =
    useResult();

  const [isPriceModal, setIsPriceModal] = useState(false);
  const [opponent, setOpponent] = useState(null);

  const onlineUsers = useMemo(
    () => allUsers.filter((val) => val.status === "online"),
    [allUsers]
  );

  const occupiedUsers = useMemo(
    () => allUsers.filter((val) => val.status === "occupied"),
    [allUsers]
  );

  const isOnline = useCallback(
    (username) => {
      return onlineUsers.find(
        (val) => val.username.toLowerCase() === username.toLowerCase()
      );
    },
    [onlineUsers]
  );

  const isOccupied = useCallback(
    (username) => {
      return occupiedUsers.find(
        (val) => val.username.toLowerCase() === username.toLowerCase()
      );
    },
    [occupiedUsers]
  );

  const isConnected = useCallback(
    (username) => {
      return isOnline(username) || isOccupied(username);
    },
    [isOnline, isOccupied]
  );

  const isOffline = useCallback(
    (username) => {
      return !isOnline(username) && !isOccupied(username);
    },
    [isOnline, isOccupied]
  );

  const handleChallenge = useCallback((player) => {
    setOpponent(player);
    setIsPriceModal(true);
  }, []);

  const sendQuick = useCallback(() => {
    // EmailNotify.sendNotificationEmail(
    //   user.username,
    //   user.email,
    //   player.username,
    //   player.email,
    //   `${user?.username} sent you a challenge. Please login https://lidarts.org and accept the challenge. Your username must be same with username of lidarts.org. The result of this challenge will not affect your ranking. It is just for fun. Enjoy!`,
    //   "Fun Challenge"
    // );
  }, []);

  const onClosePriceModal = () => setIsPriceModal(false);

  return (
    <div>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {lastSeason?.topMembers?.map((member, index) => (
          <SwiperSlide key={index}>
            <div
              style={{ backgroundImage: `url(${images.LASTTOPBG})` }}
              className="relative w-full h-full p-4 bg-cover bg-center rounded-xl"
            >
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-gray-900 from-10% to-[#56565600] to-100% rounded-xl"></div>

              <div className="absolute top-2 right-8 w-fit h-fit">
                <OnlineIndicator
                  connected={isConnected(member?.username)}
                  occupied={isOccupied(member?.username)}
                />
              </div>

              <p className="relative text-3xl font-bold text-white mb-4 uppercase tracking-widest text-left">
                {member?.username}
              </p>

              <div className="relative flex justify-center mb-4">
                {member?.avatar ? (
                  <img
                    className="border border-[#26293B] bg-white p-1 rounded-lg w-[120px] h-[120px]"
                    src={member?.avatar}
                    alt="user-avatar"
                  />
                ) : (
                  <p className="flex justify-center items-center border border-[#26293B] bg-white p-1 rounded-lg w-[120px] h-[120px] text-5xl text-white font-bold">
                    {member?.username?.toUpperCase().charAt(0)}
                  </p>
                )}
              </div>

              <button
                className="relative py-2 px-4 bg-green-500 hover:bg-green-600 text-white font-semibold shadow rounded-md disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-500"
                disabled={
                  member?.username?.toLowerCase() ===
                    user?.username?.toLowerCase() ||
                  isOffline(member?.username) ||
                  isOccupied(member?.username)
                }
                onClick={() => handleChallenge(member)}
              >
                Challenge
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <PriceModal
        isOpenModal={isPriceModal}
        type="top-quick"
        onCloseModal={onClosePriceModal}
        sendChallenge={sendQuick}
        sendQuickNotification={sendQuickFight}
        challenger={user?.username}
        receiver={opponent?.username}
      />
      <WaitingModal
        isShow={isChallengeLoading}
        text={t("waitingForAcceptance")}
        handleCancel={handleWaitingCancel}
      />
    </div>
  );
};

export default HomeTopSection;
