const HorizontalProgressBar = ({ progress }) => {
  const getGradient = (value) => {
    if (value < 0.4) {
      return "bg-gradient-to-r from-red-600 from-20% via-red-700 via-60% to-red-800 to-80%";
    } else if (value < 0.7) {
      return "bg-gradient-to-r from-red-600 from-60% to-orange-400 to-80%";
    } else {
      return "bg-gradient-to-r from-red-600 from-40% via-orange-400 via-65% to-green-400 to-95%";
    }
  };

  return (
    <div className="relative w-full h-4 bg-gray-300 overflow-hidden dark:bg-gray-800 animate-progress">
      <div
        className={`absolute left-0 h-full ${getGradient(progress)}`}
        style={{ width: `${progress * 100}%` }}
      ></div>
    </div>
  );
};

export default HorizontalProgressBar;
