import { useMemo } from "react";

const ArenaElapsedTime = ({ elapsedTime }) => {
  const time = useMemo(() => {
    return elapsedTime.split(":");
  }, [elapsedTime]);

  const data = useMemo(() => {
    return [
      {
        title: "Minutes",
        value: time[0].split(""),
      },
      {
        title: "Seconds",
        value: time[1].split(""),
      },
    ];
  }, [time]);
  return (
    <div className="flex gap-2 pt-2">
      {data.map((d, index) => (
        <div key={index}>
          <div className="flex gap-1">
            {d.value.map((v, index) => (
              <p
                key={index}
                className="bg-white py-1 px-2 rounded-sm font-semibold text-2xl font-quantico"
              >
                {v}
              </p>
            ))}
          </div>
          <p className="font-normal text-white text-sm">{d.title}</p>
        </div>
      ))}
    </div>
  );
};

export default ArenaElapsedTime;
