import HorizontalProgressBar from "../HorizontalProgressBar";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

const WinsChallenge = ({
  data = [],
  user: userself,
  handleJacksVictoryReward,
}) => {
  return (
    <div className="bg-white dark:bg-gray-900 dark:text-white p-4 rounded-md">
      <h2 className="text-left font-bold text-2xl mb-4">
        10 Wins Challenge in 48 Hours
      </h2>
      <div className="max-h-96 overflow-hedden overflow-y-auto pr-4">
        {data.map((user, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row md:items-center gap-4 mb-2"
          >
            <p className="font-semibold text-lg text-left w-60">{user?._id}</p>
            <div className="flex w-full items-center gap-4">
              <HorizontalProgressBar progress={user?.userWins / 10} />
              {user?.userWins >= 10 ? (
                <CheckCircleIcon className="h-6 w-6 text-green-500" />
              ) : (
                <p>
                  {user?.userWins}&nbsp;/&nbsp;{10}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WinsChallenge;
