import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";

import { classNames } from "../../helper/helper";

const Bell = ({
  notifications,
  handleAllNotificationsRead,
  handleNotificationClick,
}) => {
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="relative flex p-2 rounded-full text-gray-600 bg-gray-200 dark:bg-gray-900 dark:text-gray-400 text-sm">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          <BellIcon className="h-6 w-6" aria-hidden="true" />
          {notifications?.filter((val) => !val.read).length ? (
            <span className="relative flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-red-700"></span>
            </span>
          ) : (
            <span></span>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute -right-10 z-10 mt-2 w-56 max-h-56 origin-top-right rounded-md overflow-auto bg-white dark:bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {notifications?.filter((val) => !val.read).length && (
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={handleAllNotificationsRead}
                  className={classNames(
                    active ? "bg-gray-100 dark:bg-gray-900" : "",
                    "block sticky top-0 bg-white h-fit overflow-auto px-4 py-2 text-sm text-gray-700 dark:bg-gray-800 dark:text-gray-200 cursor-pointer"
                  )}
                >
                  <p className="border py-2 rounded-md font-semibold text-white cursor-pointer bg-green-500 hover:bg-green-600">
                    Mark all as read
                  </p>
                </div>
              )}
            </Menu.Item>
          )}
          {notifications
            ?.filter((val) => !val.read)
            ?.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div
                    onClick={() => handleNotificationClick(item)}
                    className={classNames(
                      active ? "bg-gray-100 dark:bg-gray-900" : "",
                      "block h-fit overflow-auto px-4 py-2 text-sm border border-t-green-500 border-b-green-500 text-gray-700 dark:bg-gray-800 dark:text-gray-200 cursor-pointer"
                    )}
                    dangerouslySetInnerHTML={{
                      __html: item?.message,
                    }}
                  />
                )}
              </Menu.Item>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Bell;
