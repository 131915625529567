import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import PriceCard from "../components/PriceCard";
import SpinnerLoading from "../components/SpinnerLoading";
import { useConstant } from "../hooks/useConstant";
import { usePrice } from "../hooks/usePrice";

const PricePage = () => {
  const { t } = useTranslation();
  const { priceCards } = useConstant();
  const { customPrice, defaultPrice, globalCoins, loading, buyCustomPrice } =
    usePrice();

  const customBalance = useMemo(
    () => (customPrice < 0 ? 0 : customPrice),
    [customPrice]
  );
  const defaultBalance = useMemo(
    () => (defaultPrice < 0 ? 0 : defaultPrice),
    [defaultPrice]
  );

  return (
    <div className="py-12 text-black dark:text-white">
      <h1 className="font-bold text-5xl tracking-widest mb-8">
        {t("pricing")}
      </h1>
      {loading ? (
        <div className="flex justify-center items-center">
          <SpinnerLoading />
        </div>
      ) : (
        <div className="flex items-center justify-center gap-4">
          <h5 className="mb-8">
            {t("currentBalance")}: {customBalance + defaultBalance}
          </h5>
          <h5 className="mb-8">
            {t("globalBalance")}: {globalCoins}
          </h5>
        </div>
      )}
      <div className="p-8 flex flex-wrap items-center justify-around sm:gap-4">
        {priceCards.map((card, index) => (
          <PriceCard
            key={index}
            price={card.price}
            msg={card.msg}
            buyCustomPrice={buyCustomPrice}
          />
        ))}
      </div>
    </div>
  );
};

export default PricePage;
