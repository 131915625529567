import React from "react";
import ScoringAvatarContainer from "./ScoringAvatarContainer";
import ScoringInputBoard from "./ScoringInputBoard";

const ScoringPlay = ({
  user,
  opponentUser,
  userCurrentScore,
  opponentCurrentScore,
  userScoreHistory,
  opponentScoreHistory,
  userWin,
  opponentWin,
  legNo,
  userTurn,
  isLoading,
  isKeyboardVisible,
  isEnableKeyEvent,
  isUndo,
  isFinished,
  handleSubmit,
  handleGameFinish,
  handleUndo,
  onClickUndo,
}) => {
  return (
    <div className="relative z-10 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-8 px-4 xl:px-8 py-2 sm:py-8">
      <div className="sm:order-1">
        <ScoringAvatarContainer
          user={user}
          userCurrentScore={userCurrentScore}
          userHistory={userScoreHistory[legNo - 1]}
          wins={userWin}
          turn={userTurn}
        />
      </div>
      <div
        className={`fixed bottom-4 sm:bottom-0 left-1/2 -translate-x-1/2 sm:relative order-last col-span-2 sm:order-2 sm:col-span-1 xl:col-span-2 z-20 ${
          isKeyboardVisible ? "visible" : "hidden"
        } sm:block`}
      >
        <ScoringInputBoard
          handleSubmit={handleSubmit}
          handleGameFinish={handleGameFinish}
          handleUndo={handleUndo}
          onClickUndo={onClickUndo}
          turn={userTurn}
          isUndo={isUndo}
          isLoading={isLoading}
          isFinished={isFinished}
          isEnableKeyEvent={isEnableKeyEvent}
        />
      </div>
      <div className="sm:order-3">
        <ScoringAvatarContainer
          user={opponentUser}
          userCurrentScore={opponentCurrentScore}
          userHistory={opponentScoreHistory[legNo - 1]}
          wins={opponentWin}
          turn={!userTurn}
        />
      </div>
    </div>
  );
};

export default ScoringPlay;
