import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import DataTable from "react-data-table-component";
import SpinnerLoading from "../SpinnerLoading";

const HomeUsersTable = () => {
  const navigate = useNavigate();
  const { allUsers, isLoading } = useSelector((state) => state.auth);

  const sortedUsers = useMemo(() => {
    return [...allUsers]?.sort((a, b) => b.dXp - a.dXp);
  }, [allUsers]);

  const handleNavigate = () => {
    navigate("/ranking");
  };

  const columns = [
    {
      name: "User Name",
      selector: (row) => row?.avatar,
      cell: (row) => (
        <div className="flex items-center space-x-4">
          <div className="flex items-center justify-center">
            {row.avatar ? (
              <img
                className="w-8 h-8 rounded-full border"
                src={row.avatar}
                alt="avatar"
              />
            ) : (
              <div className="w-8 h-8 rounded-full border text-center flex items-center justify-center font-bold text-2xl">
                {row?.username.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
          <div className="font-md">{row?.username}</div>
        </div>
      ),
    },
    {
      name: "XP",
      selector: (row) => row?.dXp,
      center: "true",
      sortable: true,
      cell: (row) => <p className="text-green-500 font-bold">{row?.dXp}</p>,
    },
  ];

  return (
    <div className="flex-1 p-4 data-table rounded-md overflow-x-auto">
      <div className="flex items-center justify-between bg-white dark:bg-gray-900 mb-2 py-4 px-6 rounded-md">
        <p className="font-bold text-xl font-poppins">Fight Club Users</p>
        <button className="text-sm text-green-500 font-bold" onClick={handleNavigate}>View All</button>
      </div>
      <DataTable
        columns={columns}
        data={sortedUsers?.slice(0, 10)}
        progressPending={isLoading}
        progressComponent={
          <div className="py-4">
            <SpinnerLoading />
          </div>
        }
        highlightOnHover
      />
    </div>
  );
};

export default HomeUsersTable;
