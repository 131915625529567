import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../CustomModal";

const ScoringModal = ({
  isOpen,
  modalType,
  possibleCheckArr,
  possibleDoubleArr,
  onClose,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const displayArr = useMemo(() => {
    if (modalType === "checkoutThrown") {
      return possibleCheckArr;
    } else if (modalType === "doubleMissed") {
      return possibleDoubleArr;
    } else if (modalType === "bustThrown") {
      return [1, 2, 3];
    } else {
      return [];
    }
  }, [modalType, possibleCheckArr, possibleDoubleArr]);

  const handleKeyDown = useCallback(
    (event) => {
      if (!isNaN(event.key) && event.key !== " ") {
        if (displayArr.includes(Number(event.key))) {
          handleSubmit(event.key);
        }
      }
    },
    [displayArr, handleSubmit]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
  return (
    <CustomModal title={t(modalType)} isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4 mt-8">
        {displayArr.map((num) => (
          <button
            key={num}
            className="bg-green-500 hover:bg-green-700 disabled:bg-gray-200 text-white font-semibold px-4 py-2 rounded-md"
            onClick={() => handleSubmit(num)}
          >
            {num}
          </button>
        ))}
      </div>
    </CustomModal>
  );
};

export default ScoringModal;
