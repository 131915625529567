import { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Pagination, Autoplay } from "swiper/modules";
import {
  cntAchievements,
  cntSeasonAchievements,
} from "../../helper/profileHelper";

const HomeTopMembers = () => {
  const navigate = useNavigate();
  const { results } = useSelector((state) => state.result);

  const sortedData = useMemo(() => {
    return [...results].sort((a, b) => {
      if (a.level === b.level) {
        return cntAchievements(b) - cntAchievements(a);
      }
      return b?.level - a?.level;
    });
  }, [results]);

  const handleNavigate = () => {
    navigate("/pyramid-users");
  };
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between bg-white dark:bg-gray-900 dark:text-white mb-2 py-4 px-6 rounded-md">
        <p className="font-bold text-xl font-poppins">Top Members</p>
        <button
          className="text-sm text-green-500 font-bold"
          onClick={handleNavigate}
        >
          View All
        </button>
      </div>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        grabCursor={true}
        modules={[Pagination, Autoplay]}
        className="bg-white dark:bg-gray-900 dark:text-white h-full rounded-md"
      >
        {sortedData?.slice(0, 4).map((member, index) => (
          <SwiperSlide key={index}>
            <div className="flex flex-col lg:flex-row px-4 pt-6 pb-12 gap-4 rounded-lg w-full relative">
              <div className="flex w-full lg:w-1/3 flex-col space-y-4 items-center">
                <div className="flex items-center justify-center">
                  {member?.avatar ? (
                    <img
                      className="w-20 h-20 rounded-full border"
                      src={member?.avatar}
                      alt="avatar"
                    />
                  ) : (
                    <p className="w-20 h-20 rounded-full border text-5xl font-bold flex justify-center items-center bg-green-600 text-white">
                      {member?.username?.toUpperCase().charAt(0)}
                    </p>
                  )}
                </div>
                <div className="font-semibold text-xl xl:text-2xl font-poppins">
                  {member?.username}
                </div>
              </div>
              <div className="flex w-full lg:w-2/3 flex-col font-poppins">
                <div className="flex flex-wrap items-center gap-4 w-full mb-4">
                  <div className="flex-1 font-md">
                    <p className="px-2 py-1 border rounded-md w-fit">
                      Level:&nbsp;
                      <span className="text-blue-600 font-bebas-neue">
                        {member?.level}
                      </span>
                    </p>
                  </div>
                  <div className="flex-1 font-md">
                    <p className="px-2 py-1 border rounded-md w-fit">
                      XP:&nbsp;
                      <span className="text-blue-600 font-bebas-neue">
                        {member?.dXp}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="flex flex-wrap gap-2 text-left">
                  <div className="flex-1">
                    <p className="font-bold mb-4 text-xl">Lifetime</p>
                    <div className="flex flex-col gap-2">
                      <p>
                        Fights:&nbsp;
                        <span>
                          {member?.readyForIt.lifetime +
                            member?.friendlyChallenger.lifetime}
                        </span>
                      </p>
                      <p>
                        Achievements:&nbsp;
                        <span>{cntAchievements(member)}</span>
                      </p>
                      <p>
                        Darts Thrown:&nbsp;
                        <span>{member?.throwCount?.lifetime}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex-1">
                    <p className="font-bold mb-4 text-xl">Season</p>
                    <div className="flex flex-col gap-2">
                      <p>
                        Fights:&nbsp;
                        <span>
                          {member?.readyForIt.season +
                            member?.friendlyChallenger.season}
                        </span>
                      </p>
                      <p>
                        Achievements:&nbsp;
                        <span>{cntSeasonAchievements(member)}</span>
                      </p>
                      <p>
                        Darts Thrown:&nbsp;
                        <span>{member?.throwCount?.season}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HomeTopMembers;
