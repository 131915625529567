import { useParams } from "react-router";

import { useFightResult } from "../hooks/useFightResult";
import { usePrompt } from "../hooks/usePrompt";
import ResultForm from "../components/Result/ResultForm";
import ResultSummaryModal from "../components/Result/ResultSummaryModal";
import AchievementModal from "../components/Result/AchievementModal";
import FightResult from "../components/Result/FightResult";

const ResultPage = () => {
  const { token, type } = useParams();

  const {
    isLoading,
    fightResult,
    isSaved,
    earnedAchievement,
    isSummaryModalOpen,
    isSaveModalOpen,
    currentEarned,
    onChange,
    updateUserStatus,
    onCloseSummaryMdal,
    onCloseSaveModal,
    handleSubmit,
    handleSave,
  } = useFightResult(token, type);

  usePrompt({
    message: "Are you sure you want to leave?",
    when: isSaved,
    customFn: () => {
      updateUserStatus();
    },
  });

  return (
    <div className="px-4 py-16">
      <ResultForm
        isLoading={isLoading}
        onChange={onChange}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
      />
      <FightResult isLoading={isLoading} result={fightResult} />
      {earnedAchievement && (
        <ResultSummaryModal
          isOpen={isSummaryModalOpen}
          onClose={onCloseSummaryMdal}
          result={fightResult}
          summary={earnedAchievement}
        />
      )}
      <AchievementModal
        show={isSaveModalOpen}
        handleClose={onCloseSaveModal}
        updatedAchievements={currentEarned}
      />
    </div>
  );
};

export default ResultPage;
