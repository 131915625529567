import MatchResultCard from "./MatchResultCard";
import LoadMoreBtn from "../LoadMoreBtn";

const ArenaMatchResults = ({
  matchResults,
  isVisibleLoadMoreBtn,
  handleLoadMore,
}) => {
  return (
    <div>
      <div className="flex items-center justify-center flex-wrap gap-4">
        {matchResults.map((result, index) => (
          <MatchResultCard key={index} match={result} />
        ))}
      </div>
      <LoadMoreBtn
        isVisible={isVisibleLoadMoreBtn}
        handleLoadMore={handleLoadMore}
      />
    </div>
  );
};

export default ArenaMatchResults;
