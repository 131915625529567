// useEvents.js
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchEvents } from "../app/eventSlice";

export const useEvents = (page, rowsPerPage, sortObj, selectedFilterItems) => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event);
  const { events, isLoading } = event;

  const filterEvents = useMemo(
    () =>
      events?.items?.map((val) => ({
        id: val._id,
        date: new Date(val.date).toLocaleString(),
        user: val?.user,
        target: val?.targetUser,
        match: val?.match,
        type: val?.eventType,
        token: val?.token,
      })),
    [events]
  );

  const totalItems = useMemo(() => events?.totalItems, [events]);

  useEffect(() => {
    dispatch(fetchEvents({ page, rowsPerPage, sortObj, selectedFilterItems }));
  }, [dispatch, page, rowsPerPage, sortObj, selectedFilterItems]);

  return { filterEvents, totalItems, isLoading };
};
