import { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import http from "../../helper/http-client";
import SpinnerLoading from "../SpinnerLoading";
import { useNavigate } from "react-router";

const RecentEvent = () => {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleNavigate = () => {
    navigate("/events");
  };

  useMemo(async () => {
    setIsLoading(true);
    try {
      const res = await http.get(
        `/event/get?page=${1}&limit=${16}&sortDirection='desc'&eventType=${[
          "match",
        ]}`
      );

      const uniqueLinks = new Set();
      const uniqueEvents = res.data.items.filter((item) => {
        if (!uniqueLinks.has(item.match.link)) {
          uniqueLinks.add(item.match.link);
          return true;
        }
        return false;
      });

      setEvents(uniqueEvents);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const columns = [
    {
      name: "Date",
      selector: (row) => row?.date,
      center: "true",
      sortable: true,
    },
    {
      name: "Challenger",
      selector: (row) => row?.user,
      center: "true",
      sortable: true,
    },
    {
      name: "Opponent",
      selector: (row) => row?.targetUser,
      center: "true",
      sortable: true,
    },
    {
      name: "Match Result",
      selector: (row) => row?.match,
      center: "true",
      sortable: true,
      cell: (row) => (
        <p className="text-green-500 font-bold">
          {row?.match?.user1Won}:{row?.match?.user2Won}
        </p>
      ),
    },
  ];

  return (
    <div className="py-4 data-table rounded-md">
      <div className="flex items-center justify-between bg-white dark:bg-gray-900 mb-2 py-4 px-6 rounded-md">
        <p className="font-bold text-xl font-poppins">Recent Events</p>
        <button
          className="text-sm text-green-500 font-bold"
          onClick={handleNavigate}
        >
          View All
        </button>
      </div>
      <DataTable
        columns={columns}
        data={events}
        progressPending={isLoading}
        progressComponent={
          <div className="py-4">
            <SpinnerLoading />
          </div>
        }
        highlightOnHover
      />
    </div>
  );
};

export default RecentEvent;
