const LoadMoreBtn = ({ isVisible = false, handleLoadMore }) => {
  return (
    <button
      className={`p-2 mt-4 border border-yellow-400 font-semibold bg-green-400 dark:bg-gray-800 text-white rounded-md ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
      onClick={handleLoadMore}
    >
      Load More
    </button>
  );
};

export default LoadMoreBtn;
