import { useTranslation } from "react-i18next";
import AvatarCard from "../../components/Shop/AvatarCard";
import useAvatarShop from "../../hooks/useAvatarShop";

const AvatarShop = () => {
  const { t } = useTranslation();

  const { user, displayAvatars, handleLvlUp } = useAvatarShop();

  return (
    <div className="text-black dark:text-white">
      <h1 className="font-semibold text-6xl uppercase mb-12">
        {t("avatarShop")}
      </h1>
      {/* display cards for buying virtual avatar */}
      <div className="flex justify-center flex-wrap gap-4">
        {displayAvatars.map((avatar) => (
          <AvatarCard user={user} avatar={avatar} handleLvlUp={handleLvlUp} />
        ))}
      </div>
    </div>
  );
};

export default AvatarShop;
