import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import AuthGuard from "../helper/ProtectRoute";
import MainLayout from "../Layout/MainLayout";
import InfoLayout from "../Layout/InfoLayout";
import ProfileLayout from "../Layout/ProfileLayout";

import Home from "../pages/Home";
import Pyramid from "../pages/Pyramid";
import Rules from "../pages/Rules";
import Additive from "../pages/Additive";
import Tutorial from "../pages/Tutorial";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Ranking from "../pages/Ranking";
import Settings from "../pages/Settings";
import Events from "../pages/Events";
import ProfileSummay from "../pages/Profile/ProfileSummary";
import ProfileUserSummay from "../pages/Profile/ProfileUserSummary";
import ProfilePyramidAchievements from "../pages/Profile/ProfilePyramidAchievements";
import ProfileClubAchievements from "../pages/Profile/ProfileClubAchievements";
import ProfilePersonAchievements from "../pages/Profile/ProfilePersonalAchievements";
import ProfileCalendar from "../pages/Profile/ProfileCalendar";
import Schedule from "../pages/Schedule";
import ResultPage from "../pages/ResultPage";
import ResetPwd from "../pages/ResetPwd";
import RetypePwd from "../pages/RetypePwd";
import TopResultPage from "../pages/TopResultPage";
import PricePage from "../pages/PricePage";
import ShopPage from "../pages/Shop/ShopPage";
import AvatarShop from "../pages/Shop/AvatarShop";
import SparringPage from "../pages/Arena/SparringPage";
import ArenaReply from "../pages/Arena/ArenaReply";
import CommunityEvents from "../pages/CommunityEvents";

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<SignIn />} />
      <Route exact path="/register" element={<SignUp />} />
      <Route path="/reset-password" element={<ResetPwd />} />
      <Route path="/retype-password/:id/:token" element={<RetypePwd />} />
      <Route element={<AuthGuard />}>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="home" element={<Home />} />
          <Route path="events" element={<Events />} />
          <Route path="settings" element={<Settings />} />
          <Route path="pyramid-users" element={<Pyramid />} />
          <Route path="infos" element={<InfoLayout />}>
            <Route path="/infos" element={<Navigate to="/infos/rules" />} />
            <Route path="/infos/rules" element={<Rules />} />
            <Route path="/infos/additive" element={<Additive />} />
            <Route path="/infos/tutorial" element={<Tutorial />} />
          </Route>
          <Route path="/profile/:username" element={<ProfileUserSummay />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route
              path="/profile"
              element={<Navigate to="/profile/summary" />}
            />
            <Route path="/profile/summary" element={<ProfileSummay />} />
            <Route
              path="/profile/pyramid"
              element={<ProfilePyramidAchievements />}
            />
            <Route
              path="/profile/participation"
              element={<ProfileClubAchievements />}
            />
            <Route
              path="/profile/personal"
              element={<ProfilePersonAchievements />}
            />
            <Route path="/profile/calendar" element={<ProfileCalendar />} />
          </Route>
          <Route path="ranking" element={<Ranking />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="result/:token/:type" element={<ResultPage />} />
          <Route path="top-challenge-result" element={<TopResultPage />} />
          <Route path="price" element={<PricePage />} />
          <Route path="community" element={<CommunityEvents />} />
          <Route path="shop" element={<ShopPage />}>
            <Route path="/shop" element={<Navigate to="/shop/avatar-shop" />} />
            <Route path="/shop/avatar-shop" element={<AvatarShop />} />
          </Route>
          <Route path="arena">
            <Route path="sparring" element={<SparringPage />} />
            <Route path="reply" element={<ArenaReply />} />
          </Route>
          <Route path="*" element={<Navigate to="/home" />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
